import React, { useState } from 'react';
import { Select, MenuItem } from '@mui/material';

const UserAgent = ({ setSelectedUserAgent, isDisable }) => {
  const [selectedUserAgentLocal, setSelectedUserAgentLocal] = useState('Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36');

  const userAgentsList = [
    { label: 'Google Chrome (Windows 10)', value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36' },
    { label: 'Mozilla Firefox (Windows 10)', value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:94.0) Gecko/20100101 Firefox/94.0' },
    { label: 'Safari (macOS Big Sur)', value: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_16_0) AppleWebKit/537.36 (KHTML, like Gecko) Version/14.1.2 Safari/537.36' },
    { label: 'Microsoft Edge (Windows 10)', value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:94.0) Gecko/20100101 Edge/94.0.992.50' },
    { label: 'Internet Explorer 11 (Windows 7)', value: 'Mozilla/5.0 (Windows NT 6.1; Trident/7.0; AS; rv:11.0) like Gecko' },
    { label: 'Googlebot (Desktop)', value: 'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)' },
    { label: 'Bingbot (Desktop)', value: 'Mozilla/5.0 (compatible; bingbot/2.0; +http://www.bing.com/bingbot.htm)' },
    { label: 'Slackbot', value: 'Mozilla/5.0 (compatible; Slackbot/1.0; +https://api.slack.com/docs/slackbot)' },
    { label: 'Pinterest Bot', value: 'Mozilla/5.0 (compatible; Pinterest/0.0; +https://www.pinterest.com/)' },
    { label: 'Twitter Bot', value: 'Twitterbot/1.0' },
    { label: 'Amazon Alexa Bot', value: 'Mozilla/5.0 (compatible; AlexaBot/2.0; +http://www.alexa.com)' }
  ];

  const handleChange = (event) => {
    setSelectedUserAgentLocal(event.target.value);
    setSelectedUserAgent(event.target.value);
  };

  return (
    <div className="oom-field-container oom-custom-select-container">
      <Select
        value={selectedUserAgentLocal}
        onChange={handleChange}
        displayEmpty
        fullWidth
        disabled={isDisable}
      >
        <MenuItem value="" disabled>Select User-Agent</MenuItem>
        {userAgentsList.map((ua, index) => (
          <MenuItem key={index} value={ua.value}>{ua.label}</MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default UserAgent;
