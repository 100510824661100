import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip);

const SiteHealthScore = () => {
  const percentage = 60; 
  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: ["#4285F4", "#E0E0E0"], 
        borderWidth: 0, 
        borderRadius: 0,
      },
    ],
  };

  const options = {
    cutout: "85%",
    plugins: {
      tooltip: { enabled: false }, 
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  return (
    <div className="site-health-container">
      <div className="doughnut-chart-wrapper">
        <Doughnut data={data} options={options} />
        <div className="doughnut-chart-text">
          <p className="percentage-value">{percentage}%</p>
          <p className="status-text">GOOD</p>
        </div>
      </div>
      <p className="overall-score">Overall Health Score {percentage}%</p>
      <p className="comparison-text">Better than 34% of Users</p>
    </div>
  );
};

export default SiteHealthScore;
