import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './LoginRegister.module.css';
import { fieldValidator } from '../../utils/fieldValidator';

export default function LoginPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyWesbiteURL, setCompanyWebsiteURL] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = '#2C81FF';
        return () => {
            document.body.style.backgroundColor = ''; 
        }
    }, []);

    const handleRegister = async (e) => {
        e.preventDefault();
    
        if (!firstName) {
            toast.error("Please enter your First name");
            return;
        }

        if (firstName.length <= 2 || !fieldValidator(firstName, 'text') ) {
            toast.error("Please enter a valid First name");
            return;
        }
    
        if (!lastName) {
            toast.error("Please enter your Last name");
            return;
        }

        if (!companyName) {
            toast.error("Please enter your Company Name");
            return;
        }

        if (lastName.length <= 2 || !fieldValidator(lastName, 'text') ) {
            toast.error("Please enter a valid Last name");
            return;
        }

        if (!companyWesbiteURL) {
            toast.error("Please enter your Company Website");
            return;
        }

        if (companyWesbiteURL.length <= 2 || !fieldValidator(companyWesbiteURL, 'url')) {
            toast.error("Please enter a valid website URL");
            return;
        }
    
        if (!username) {
            toast.error("Please enter your Username");
            return;
        }

        if (!fieldValidator(username, 'username')) {
            toast.error("Your username must be at least 5 characters long and consist of letters, numbers, and underscores only");
            return;
        }
    
        if (!email) {
            toast.error("Please enter your Email");
            return;
        }
        if (!fieldValidator(email, 'email')) {
            toast.error("Please enter a valid Email");
            return;
        }
    
        if (!password || !confirmPassword) {
            toast.error("Please enter and confirm your Password");
            return;
        }
        if (password !== confirmPassword) {
            toast.error("Password and Confirm Password don't match");
            return;
        }
    
        console.log(companyWesbiteURL);
        const endPointUserRegister = process.env.REACT_APP_OOM_SEO_API_USER_REGISTER;
        setLoading(true);

        try {
            const response = await axios.post(endPointUserRegister, { 
                first_name: firstName,
                last_name: lastName,
                company_name: companyName,
                company_website: companyWesbiteURL,
                username: username,
                email: email, 
                password: password
            });

            if (response.status === 201) {
                toast.success("Registration successful, please wait...");
                setTimeout(() => {
                    navigate("/login");
                }, 1000);
            }
        } catch (err) {
            console.error('Error while registering', err);
            // Check if the error is caused by a 409 Conflict
            if (err.response && err.response.status === 409) {
                toast.error("Username or Email already exists. Please try a different one.");
            } else {
                toast.error("Something went wrong, please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles['oom-login-register']}>
                <div className={styles['oom-login-register__container']}>
                    <div className={styles['oom-login-register__logo']}>
                        <span>OOm</span>
                    </div>

                     <form className={styles['oom-login-register__form']}>
                        <div className={`${styles['oom-login-register__row']} ${styles['heading']}`}>
                            <h2>Register</h2>
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['login']}`}>
                            <input
                                type="text"
                                className={styles['oom-field']}
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['login']}`}>
                            <input
                                type="text"
                                className={styles['oom-field']}
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['login']}`}>
                            <input
                                type="text"
                                className={styles['oom-field']}
                                placeholder="Company Name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['login']}`}>
                            <input
                                type="url"
                                className={styles['oom-field']}
                                placeholder="Company Website e.g. oom.com.sg"
                                value={companyWesbiteURL}
                                onChange={(e) => setCompanyWebsiteURL(e.target.value)}
                            />
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['login']}`}>
                            <input
                                type="text"
                                className={styles['oom-field']}
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['login']}`}>
                            <input
                                type="text"
                                className={styles['oom-field']}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['password']}`}>
                            <input
                                type={showPassword ? "text" : "password"}
                                className={styles['oom-field']}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="on"
                            />

                            <div
                                className={styles['password-visibility-icon']}
                                onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                                title={showPassword ? "Hide Password" : "Show Password"}
                            >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </div>
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['password']}`}>
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                className={styles['oom-field']}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />

                            <div
                                className={styles['password-visibility-icon']}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle visibility
                                title={showConfirmPassword ? "Hide Password" : "Show Confirm Password"}
                            >
                                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </div>
                        </div>
                        
                        <div className={`${styles['oom-login-register__row']} ${styles.action}`}>
                            <button onClick={handleRegister} title="Register" disabled={loading}>{loading ? 'loading...' : 'Register'}</button>
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['divider']}`}>
                            <span className={styles['small-text']}>Or</span>
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['register-login']}`}>
                            <span>Already have account?</span>
                            <a href="/login">Login Now</a>
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['policy-links']}`}>
                            <p><a href="https://www.oom.com.sg/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://www.oom.com.sg/privacy-policy/" target="_blank" rel="noreferrer">Terms & Conditions</a>,  Apply.</p>
                        </div>

                        <div className={`${styles['oom-login-register__row']} ${styles['version']}`}>
                            <span className={styles['small-text']}>beta v1.0.0</span>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};