
/**
 * Page Controller
 *
 * This controller handles the logic for retrieving and displaying different pages in the application. 
 * Based on the input parameters (`page`, `subpage`, and `id`), it returns the appropriate component for rendering.
 *
 * @since 1.0.0
 * @version 1.0.0
 * 
 * @package OOmAISEOTools
 * @package page-controller 
 * 
 * @author  OOm Developer (oom_ss)
 */

import Dashboard from '../views/Dashboard';
import Projects from '../views/Projects/Projects';
import ProjectCreate from '../views/Projects/Create';
import ProjectAuditResults from '../views/Projects/ProjectAuditResults';
import AuditWebsites from '../views/Audit/AuditWebsites';
import AuditPage from '../views/Audit/AuditPage';
import AuditResults from '../views/Audit/AuditResults';
import Traffic from '../views/AnalyticsTraffic/Traffic';
import ContentGeneration from '../views/AiGeneration/ContentGeneration';
import ContentsGeneration from '../views/AiGeneration/ContentsGeneration';
import KeywordSuggestion from '../views/Research/KeywordSuggestion';
import KeywordOverview from '../views/Research/KeywordOverview';
import KeywordManager from '../views/Research/KeywordManager';
import CompetitorComparison from '../views/Research/CompetitorComparison';
import Profile from '../views/User/Profile';
import NotFound from '../views/NotFound';

const PageController = {
  getPageContent: (page, subpage, id) => {
    switch (page) {
      case 'dashboard':
        return Dashboard;
      case 'projects':
        if (id === "create") {
          return ProjectCreate;
        } else if (id !== "create" && !subpage) {
          return Projects;
        } else if (id && subpage && subpage === 'audit-results') {
          return ProjectAuditResults;
        } 
        return NotFound;
      case 'analytics-traffic':
        if (id && subpage && subpage === 'traffic') {
            return Traffic;
        }
        return NotFound;
      case 'audit':
        if (id === "new") {
          return AuditPage;
        } else if (id === "websites") {
          return AuditWebsites;
        } else if (id && subpage && subpage === 'websites') {
          return AuditWebsites;
        } else if (id && subpage && subpage === 'results') {
          return AuditResults;
        } 
        return NotFound;
      case 'ai-generation':
        if(id === 'contents') {
          return ContentsGeneration;
        } else {
          return ContentGeneration;
        }
      case 'research':
        if (id === 'keyword-suggestion') {
          return KeywordSuggestion;
        } else if (id === 'keyword-overview') {
          return KeywordOverview;
        } else if (id === 'keywords-manager') {
          return KeywordManager;
        } else if (id === 'competitor-comparison') {
          return CompetitorComparison;
        } else {
          return NotFound;
        }
      case 'user':
        if(id === 'profile') {
          return Profile;
        } else {
          return NotFound;
        }
      default:
        return NotFound;
    }
  },
};

export default PageController;
