/**
 * Formats a given URL to ensure it includes the HTTPS protocol and removes trailing slashes.
 *
 * This function ensures that a URL is formatted consistently by adding the `https://` protocol
 * if it is missing and removing any trailing slashes (`/`) from the URL. It does not remove the
 * `www.` prefix or handle invalid URLs extensively, but it returns an empty string (`''`) if the
 * input is not parseable.
 *
 * @since 1.0.0
 * @version 1.0.0
 * 
 * @package URLUtilities
 * 
 * @author OOm Developer (oom_ss)
 *
 * @example
 * const formattedUrl1 = formatUrl("www.example.com");
 * console.log(formattedUrl1); // Outputs: "https://www.example.com"
 *
 * const formattedUrl2 = formatUrl("http://example.com/");
 * console.log(formattedUrl2); // Outputs: "https://example.com"
 *
 * const formattedUrl3 = formatUrl("example.com");
 * console.log(formattedUrl3); // Outputs: "https://example.com"
 *
 * const formattedUrl4 = formatUrl("invalid-url");
 * console.log(formattedUrl4); // Outputs: ""
 *
 * @param {string} url - The URL to be formatted. It may or may not include a protocol (http://, https://)
 *                       and may contain trailing slashes.
 * 
 * @returns {string} The formatted URL with the `https://` protocol and no trailing slashes. 
 *                   Returns an empty string (`''`) if the input URL cannot be parsed correctly.
 */

export const formatUrl = (url) => {
    try {
        // Ensure the URL is correctly formatted by adding 'http://' if no protocol is present
        let formattedUrl = url.startsWith("https://") ? url : `https://${url}`;
        formattedUrl = formattedUrl.replace(/\/$/, "");
        return formattedUrl;
    } catch (error) {
        // Return an empty string or handle invalid URL here
        return '';
    }
};