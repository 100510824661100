/**
 * Profile
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */
import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

export default function Profile({profile}) {
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');


    useEffect(() => {
    console.log(profile);

        if (profile) {
            setDisplayName(profile.displayName || '');
            setCompanyName(profile.companyName || '');
            setCompanyWebsite(profile.companyWebsite || '');
        }

        if (profile && profile.userMeta) {
            setFirstName(profile.userMeta.firstName || '');
            setLastName(profile.userMeta.lastName || '');
        }
    }, [profile]);

    return (
        <React.Fragment>
            <div className="oom-page-attributes breadcrumb profile">
                <p className="oom-page-attributes_breadcrumb">Dashboard / <span className="highlight">Profile</span></p>
                <p className="oom-page-attributes_title">
                    Profile Settings / <span className="highlight">{displayName}</span>
                </p>
            </div>

            <div className="oom-form_box profile-form">
                <p className="oom-form_title">Account Details</p>
                <div className="oom-form">
                    <div className="oom-field-container__row two-columns">
                        <div className="oom-field-container__row">
                            <div className="oom-field-container__row_heading">
                                <span className="container__heading">First Name</span>
                            </div>

                            <div className='oom-field oom-text-field'>
                                <TextField
                                    variant="outlined"
                                    placeholder='Enter your First Name'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>

                        <div className="oom-field-container__row">
                            <div className="oom-field-container__row_heading">
                                <span className="container__heading">Last Name</span>
                            </div>

                            <div className='oom-field oom-text-field'>
                                <TextField
                                    variant="outlined"
                                    placeholder='Enter your Last Name'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>

                    <div className="oom-field-container__row two-columns">
                        <div className="oom-field-container__row">
                            <div className="oom-field-container__row_heading">
                                <span className="container__heading">Company Name</span>
                            </div>

                            <div className='oom-field oom-text-field'>
                                <TextField
                                    variant="outlined"
                                    placeholder='Enter your Company Name'
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>

                        <div className="oom-field-container__row">
                            <div className="oom-field-container__row_heading">
                                <span className="container__heading">Website</span>
                            </div>

                            <div className='oom-field oom-text-field'>
                                <TextField
                                    type="url"
                                    variant="outlined"
                                    placeholder='Enter your Last Name'
                                    value={companyWebsite}
                                    onChange={(e) => setCompanyWebsite(e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </React.Fragment>
    );
};