/**
 * Custom Hook to Fetch Audit Website Data
 *
 * This hook retrieves audit website data from the API based on a provided `website_id`. It handles the loading, error, and data states. 
 * The hook makes an asynchronous request to fetch the details of an audit website, and returns the fetched data, loading state, and any errors that may occur during the request.
 * If the `website_id` is "new", no API call is made, as it represents a new audit website.
 *
 * @since 1.0.0
 * @version 1.0.0
 *
 * @package OOmAISEOTools
 * @package react-hooks
 * 
 * @author OOm Developer (oom_ss)
 *
 * @example
 * const { auditWebsite, loading, error } = useAuditWebsite(website_id, headers);
 * // `auditWebsite` will contain the fetched audit website data.
 * // `loading` is `true` while the data is being fetched.
 * // `error` will contain any error message if the request fails.
 */

import { useState, useEffect } from 'react';
import axios from 'axios';

const useAuditWebsite = (website_id, headers) => {
    const [auditWebsite, setAuditWebsite] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(website_id && website_id !== "new") {
            const fetchAuditWebsite = async () => {
                const endPointAuditWebsite = process.env.REACT_APP_OOM_SEO_API_AUDIT_WEBSITE;
                setLoading(true);
                try {
                    const response = await axios.get(`${endPointAuditWebsite}/${website_id}`, { headers });
                    setAuditWebsite(response.data.audit_website);
                } catch (err) {
                    setError('Error retrieving audit website');
                    console.error('Error:', err);
                } finally {
                    setLoading(false);
                }
            };
            fetchAuditWebsite();
        }
    }, [website_id, headers]);

    return { auditWebsite, loading, error };
};

export default useAuditWebsite;
