/**
 * Validates a given string based on the specified validation type (email, URL, name, or username).
 *
 * This function accepts a string and a validation type (email, URL, name, or username), and checks the string 
 * against the corresponding regular expression. It returns `true` if the string is valid for the specified type, 
 * or `false` if it is not. The validation types supported are:
 * - "email": Validates an email address.
 * - "url": Validates a URL.
 * - "name": Validates a name consisting of only alphabetic characters and spaces.
 * - "username": Validates a username consisting of alphanumeric characters and underscores, with a minimum length of 5.
 *
 * @since 1.0.0
 * @version 1.0.0
 *
 * @package ValidationUtilities
 * 
 * @author OOm Developer (oom_ss)
 *
 * @example
 * const isValidEmail = fieldValidator("test@example.com", "email");
 * console.log(isValidEmail); // Outputs: true
 *
 * const isValidUrl = fieldValidator("https://example.com", "url");
 * console.log(isValidUrl); // Outputs: true
 *
 * const isValidName = fieldValidator("John Doe", "name");
 * console.log(isValidName); // Outputs: true
 *
 * const isValidUsername = fieldValidator("user_name123", "username");
 * console.log(isValidUsername); // Outputs: true
 *
 * @param {string} str - The string to be validated.
 * @param {string} type - The type of validation to perform. Can be one of "email", "url", "name", or "username".
 * @returns {boolean} `true` if the string is valid for the specified type, `false` otherwise.
 * 
 * @throws {Error} If the `type` is not one of the valid types ("email", "url", "name", or "username").
 */

export const fieldValidator = (str, type) => {
    switch (type) {
        case 'email':
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(str);
        
        case 'url':
            const urlRegex = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/[^\s]*)?$/i;
            return urlRegex.test(str);
        
        case 'text':
            const nameRegex = /^[A-Za-z\s]+$/;
            return nameRegex.test(str);
        
        case 'username':
            const usernameRegex = /^[A-Za-z_][A-Za-z0-9_]{4,}$/;
            return usernameRegex.test(str);
        
        default:
            throw new Error('Invalid type specified for validation');
    }
};