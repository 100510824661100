/**
 * Keyword Overview
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthHeaders } from '../../context/AuthContext';
import useGeoTargetConstants from '../../hooks/useGeoTargetConstants';

import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { toast } from 'react-toastify';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkIcon from '@mui/icons-material/Link';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PlaceIcon from '@mui/icons-material/Place';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InfoIcon from '@mui/icons-material/Info';

import Loader from '../../components/elements/Loader';

import CustomSelect from '../../components/widgets/CustomSelect';
import MonthlySearchChart from '../../components/widgets/MonthlySearchChart';

import { formatNumber } from '../../utils/formatNumber';
import { searchIntent } from '../../utils/searchIntent';

import "../../assets/css/KeywordOverview.css";

export default function KeywordOverview() {
    const headers = useAuthHeaders();
    const { geoTargetData } = useGeoTargetConstants(headers);

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [intents, setIntents] = useState([]);
    const [serpFeatures, setSerpFeatures] = useState([]);
    const [keywordGlobalMetricsLoading, setKeywordGlobalMetricsLoading] = useState(false);
    const [keywordsIdeasLoading, setKeywordsIdeasLoading] = useState(false);
    const [searchResultsLoading, setSearchResultsLoading] = useState(false);
    const [peopleAlsoAskLoading, setPeopleAlsoAskLoading] = useState(false);

    const [selectedCountries, setSelectedCountries] = useState([]);

    const [selectedChartData, setSelectedChartData] = useState(null);
    const [monthlySearchVolumesModal, setMonthlySearchVolumesModal] = useState(false);

    const countryOptions = geoTargetData ? geoTargetData.map(country => ({
        label: country.label,
        value: country.value,
        code: country.country_code
    })) : [];

    const [keywordsIdeas, setKeywordsIdeas] = useState([]);
    const [keywordGlobalMetrics, setkeywordGlobalMetrics] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const [inputKeyword, setInputKeyword] = useState("");

    const [searchResults, setSearchResults] = useState([]);
    const [peopleAlsoAsk, setPeopleAlsoAsk] = useState([]);

    const itemsPerPage = 6;

    const currentItems = keywordsIdeas.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryLocation = queryParams.get('locations');
        if (geoTargetData && !queryLocation) {
            const singapore = geoTargetData.find(country => country.label === "Singapore");
            if (singapore) {
                setSelectedCountries([{
                    label: singapore.label,
                    value: singapore.value,
                    code: singapore.country_code,
                }]);
            }
        }
    }, [geoTargetData]);

    // useEffect to read the query parameter and set the keywords when the component mounts
    useEffect(() => {
        const initiateQueryData = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const queryKeywords = queryParams.get('keywords');
            const queryLocation = queryParams.get('locations');

            if (queryKeywords) {
                // Set keywords from query parameters
                setInputKeyword(queryKeywords.trim()); // Update state
            }

            if (queryLocation) {
                const endPointGeoTargetContants = process.env.REACT_APP_OOM_SEO_API_GEO_TARGET_CONSTANTS;
                try {
                    const response = await axios.get(`${endPointGeoTargetContants}/${queryLocation}`, { headers });
                    if (response.data.status === 200) {
                        const location = response.data.location;
                        setSelectedCountries([{
                            label: location.label,
                            value: location.value,
                            code: location.country_code,
                        }]);
                    }
                } catch (err) {
                    console.error('Error retrieving geo target data:', err);
                }
            }
        };

        initiateQueryData();
    }, [headers]);

    const handleCountrySelect = (selectedItems) => {
        if (selectedItems.length > 0) {
            setSelectedCountries([selectedItems[0]]);
        }
    };

    const handleCountryRemove = (countryToRemove) => {
        setSelectedCountries(selectedCountries.filter(country => country.value !== countryToRemove.value));
    };

    const handleInputChangeKeyword = (e) => {
        setInputKeyword(e.target.value);
    };

    const handleSearchKeywords = async () => {
        if (inputKeyword.length === 0) {
            toast.error("Please enter keyword");
            return;
        }

        if (selectedCountries.length === 0) {
            toast.error("Please select location");
            return;
        }

        // Construct the new URL with the input keyword and selected countries
        const newUrl = new URL(window.location);
        newUrl.searchParams.set("keywords", inputKeyword); // Add keyword to the URL
        newUrl.searchParams.set("locations", selectedCountries.map(country => country.value).join(',')); // Add location parameter
        window.history.pushState({}, '', newUrl); // Update URL in browser

        // Set loading states
        setLoading(true);
        setKeywordGlobalMetricsLoading(true);
        setKeywordsIdeasLoading(true);
        setSearchResultsLoading(true);
        setPeopleAlsoAskLoading(true);
        setSearch(true);

        // Function to get Search Intent
        const getGlobalMetrics = async () => {
            const endPointKeywordVolume = process.env.REACT_APP_OOM_SEO_API_KEYWORD_VOLUME;
            try {
                const response = await axios.get(endPointKeywordVolume, {
                    params: {
                        keyword: inputKeyword.toLowerCase(),
                        start_with: "keywords",
                        save_keywords : "no",
                    },
                    headers,
                });
                if (response.data.status === 200) {
                    setkeywordGlobalMetrics(response.data.keyword_metrics);
                    setKeywordGlobalMetricsLoading(false);
                }
            } catch (error) {
                console.error('Error getting global keyword metric:', error);
            }
        };

        // Function to get Search Intent
        const getSearchIntent = async () => {
            const endPointSearchIntent = process.env.REACT_APP_OOM_SEO_API_SEARCH_INTENT;
            try {
                const response = await axios.get(endPointSearchIntent, {
                    params: {
                        keyword: inputKeyword.toLowerCase(),
                    },
                    headers,
                });
                console.log(response);
                if (response.data.status === 200) {
                    setIntents(response.data.intents);
                }
            } catch (error) {
                console.error('Error getting keyword intent:', error);
            }
        };

        // Function to get Keyword Ideas
        const getKeywordsIdeas = async () => {
            const endPointKeywordIdeas = process.env.REACT_APP_OOM_SEO_API_KEYWORD_IDEAS;
            try {
                const response = await axios.get(endPointKeywordIdeas, {
                    params: {
                        keywords: inputKeyword.toLowerCase(),
                        locations: selectedCountries.map(country => country.value).join(','),
                        start_with: "keywords",
                        use_type: "url_seed",
                        page_size: "5"
                    },
                    headers,
                });
                if (response.data.status === 200 && response.data.keywords_ideas.length > 0) {
                    setKeywordsIdeas(response.data.keywords_ideas);
                    setCurrentPage(1);
                }
                setKeywordsIdeasLoading(false);
            } catch (error) {
                console.error('Error generating keyword ideas:', error);
            }
        };

        // Function to get SERP Analysis
        const getSERPAnalysis = async () => {
            const endPointSERPAnalysis = process.env.REACT_APP_OOM_SEO_API_SERP_ANALYSIS;
            try {
                const response = await axios.get(endPointSERPAnalysis, {
                    params: {
                        keywords: inputKeyword.toLowerCase(),
                        location: selectedCountries[0].code
                    },
                    headers,
                });

                if (response.data.status === 200) {
                    const features = response.data.features;
                    const analysis = response.data.analysis;

                    if (features) {
                        setSerpFeatures(features);
                    }

                    if (Array.isArray(analysis?.search_results?.results) && analysis.search_results.results.length > 0) {
                        setSearchResults(analysis.search_results.results);
                        setSearchResultsLoading(false);
                    }

                    if (Array.isArray(analysis?.people_also_ask?.results) && analysis.people_also_ask.results.length > 0) {
                        setPeopleAlsoAsk(analysis.people_also_ask.results);
                        setPeopleAlsoAskLoading(false);
                    }
                }
            } catch (error) {
                console.error('Error getting SERP:', error);
            }
        };

        try {
            // Run all the API calls concurrently using Promise.all()
            await Promise.all([
                getGlobalMetrics(),
                getSearchIntent(),
                getKeywordsIdeas(),
                getSERPAnalysis()
            ]);
        } catch (error) {
            console.error('Error handling search keywords:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (data) => {
        setSelectedChartData(data);
        setMonthlySearchVolumesModal(true);
    };

    const handleCloseModal = () => {
        setMonthlySearchVolumesModal(false);
        setSelectedChartData(null);
    };

    return (
        <React.Fragment>
            <div className="oom-page-header">
                <div className={`oom-page-attributes breadcrumb keyword-research`}>
                    <p className="oom-page-attributes_breadcrumb">Research / <span className="highlight">Keyword Overview</span></p>
                    <p className="oom-page-attributes_title">
                        Research <span className="highlight">/ Keyword Overview</span>
                    </p>
                </div>
            </div>
            
            <div className="oom-page-content">
                <div className={`oom-form keyword-overview ${search ? 'searched' : ''}`}>
                    {!search && (
                        <span className="oom-form_title">Keyword Overview</span>
                    )}

                    <div className='oom-form_list_container'>
                        <div className="oom-form_list_container__row">
                            <div className={`oom-field-container keywords`}>
                                <div className="custom-inputs">
                                    <input
                                        type="text"
                                        value={inputKeyword}
                                        onChange={handleInputChangeKeyword}
                                        disabled={loading}
                                        placeholder="Try &quot;meal delivery&quot; or &quot;leather boots&quot;"
                                    />
                                </div>
                            </div>

                            <CustomSelect
                                options={countryOptions}
                                selectedItems={selectedCountries}
                                onSelect={handleCountrySelect}
                                onRemove={handleCountryRemove}
                                disabled={loading}
                                placeholder={
                                    <>
                                        <span className="select-item">Select location</span>
                                        <KeyboardArrowDownIcon sx={{ fontSize: 18 }} />
                                    </>
                                }
                            />
                        </div>

                        <div className="oom-form_list_container__row">
                            <div className="oom-field-container action">
                                <button onClick={handleSearchKeywords} title="Search Keywords" className="oom-button oom-btn-loader action" disabled={loading}>
                                    <span className='text'>Search</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {search && (
                    <div className="oom-container">
                        <div className="oom-column-50">
                            <div className="oom-page-results">
                                <div className='oom-page-results__details'>
                                    <div className="oom-page-results__keyword-volume">
                                        <div className="oom-page-results__details_results">
                                            <div className="card__item">
                                                <div className="card__label">
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                <strong>LOCAL VOLUME</strong>
                                                                <p>The average monthly search volume for a specific keyword over the past 12 months.</p>
                                                            </>
                                                        }
                                                        className="tooltip-icon"
                                                        placement="top-start"
                                                        followCursor
                                                    >
                                                        <span><InfoIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                    </Tooltip>
                                                    <span>LOCAL VOLUME</span>
                                                </div>

                                                <div className="card__value">
                                                    {keywordsIdeasLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        keywordsIdeas && keywordsIdeas[0] && (
                                                            // Extracting the last month's search volume
                                                            (() => {
                                                                const keywordMonthlySearchVolumes = keywordsIdeas[0].keyword_idea_metrics?.monthly_search_volumes || [];
                                                                const lastSearchVolume = keywordMonthlySearchVolumes.slice(-1)[0]?.monthly_searches;

                                                                // Return the formatted search volume
                                                                return <span>{formatNumber(lastSearchVolume)}</span>;
                                                            })()
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="oom-page-results__details_results">
                                            <div className="card__item">
                                                <div className="card__label">
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                <strong>GLOBAL VOLUME</strong>
                                                                <p>The average monthly search volume for a specific keyword over the past 12 months globally.</p>
                                                            </>
                                                        }
                                                        className="tooltip-icon"
                                                        placement="top-start"
                                                        followCursor
                                                    >
                                                        <span><InfoIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                    </Tooltip>
                                                    <span>GLOBAL VOLUME</span>
                                                </div>

                                                <div className="card__value">
                                                    {keywordGlobalMetricsLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        keywordGlobalMetrics && keywordGlobalMetrics[0] && (
                                                            (() => {
                                                                const keywordMonthlySearchVolumes = keywordGlobalMetrics[0].keyword_idea_metrics?.monthly_search_volumes || [];
                                                                const lastSearchVolume = keywordMonthlySearchVolumes.slice(-1)[0]?.monthly_searches;
                                                                return <span>{formatNumber(lastSearchVolume)}</span>;
                                                            })()
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="oom-column-50">
                            <div className="oom-page-results">
                                <div className='oom-page-results__details'>
                                    <div className="oom-page-results__keyword-difficulty-intent">
                                        <div className="oom-page-results__details_results">
                                            <div className="card__item search-intent">
                                                <div className="card__label">
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                <strong>Search Intent</strong>
                                                                <p>The goal behind a user's search query, helping search engines deliver the most relevant results. There are four main types.</p>
                                                            </>
                                                        }
                                                        className="tooltip-icon"
                                                        placement="top-start"
                                                        followCursor
                                                    >
                                                        <span><InfoIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                    </Tooltip>
                                                    <span>SEARCH INTENT</span>
                                                </div>

                                                <div className="card__value">
                                                    {Array.isArray(intents) && intents.length > 0 && intents.map((intent, index) => {
                                                        const description = searchIntent(intent.toLowerCase()); // Get the description for the intent
                                                        return (
                                                            <Tooltip
                                                                key={index}
                                                                title={
                                                                    <div>
                                                                        <strong>{intent.toUpperCase()}</strong>
                                                                        <p>{description}</p>
                                                                    </div>
                                                                }
                                                                className="tooltip-icon"
                                                                placement="top-start"
                                                                followCursor
                                                            >
                                                                <span className={`search-intent ${intent.toLowerCase()}`}>
                                                                    {intent.toUpperCase()}
                                                                </span>
                                                            </Tooltip>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="oom-page-results__details_results">
                                            <div className="card__item cpc">
                                                <div className="card__label">
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                <strong>CPC</strong>
                                                                <p> CPC stands for Cost-Per-Click, which refers to the average amount advertisers pay for a single click on their ad when bidding on a specific keyword.</p>
                                                            </>
                                                        }
                                                        className="tooltip-icon"
                                                        placement="top-start"
                                                        followCursor
                                                    >
                                                        <span><InfoIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                    </Tooltip>
                                                    <span>CPC</span>
                                                </div>

                                                <div className="card__value">
                                                    <div className="card__value_item cpc-low-range">
                                                        <span className="label">LOW RANGE</span>
                                                        <span className="value">
                                                            SGD&nbsp;
                                                            {keywordsIdeasLoading ? (
                                                                <>...</>
                                                            ) : (
                                                                keywordsIdeas && keywordsIdeas[0] && (
                                                                    (() => {
                                                                        const lowRange = (keywordsIdeas[0].keyword_idea_metrics?.low_top_of_page_bid_micros / 1000000 || 0).toFixed(2);
                                                                        return <>{lowRange}</>;
                                                                    })()
                                                                )
                                                            )}
                                                        </span>
                                                    </div>

                                                    <div className="card__value_item cpc-high-range">
                                                        <span className="label">HIGH RANGE</span>
                                                        <span className="value">
                                                            SGD&nbsp;
                                                            {keywordsIdeasLoading ? (
                                                                <>...</>
                                                            ) : (
                                                                keywordsIdeas && keywordsIdeas[0] && (
                                                                    (() => {
                                                                        const lowRange = (keywordsIdeas[0].keyword_idea_metrics?.high_top_of_page_bid_micros / 1000000 || 0).toFixed(2);
                                                                        return <>{lowRange}</>;
                                                                    })()
                                                                )
                                                            )}
                                                        </span>
                                                    </div>

                                                    <div className="card__value_item competition">
                                                        <span className="label">COMPETITION</span>
                                                        <span className="value">
                                                            {keywordsIdeasLoading ? (
                                                                <>...</>
                                                            ) : (
                                                                keywordsIdeas && keywordsIdeas[0] && (
                                                                    (() => {
                                                                        return <>{keywordsIdeas[0].keyword_idea_metrics?.competition || "N/A"}</>;
                                                                    })()
                                                                )
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}

                {search && (
                    <>

                        <div className="keyword-ideas">
                            <div className="keyword-ideas__results">
                                <div className="oom-page-results">
                                    <div className='oom-page-results__details'>
                                        <div className="oom-page-results__details_results">
                                            <div className="oom-page-results__details_results_heading">
                                                <span>Keyword Ideas</span>
                                            </div>

                                            {keywordsIdeasLoading && (
                                                <Loader />
                                            )}

                                            {!keywordsIdeasLoading && keywordsIdeas?.length > 0 && (
                                                <table className="oom-page-results__details_results_table">
                                                    <thead className="oom-page-results__details_results_table_heading">
                                                        <tr>
                                                            <th className="oom-page-results__details_results_table_heading_item oom-column-50">
                                                                <span>Keywords</span>
                                                            </th>
                                                            <th className="oom-page-results__details_results_table_heading_item oom-column-20">
                                                                <span>Volume</span>
                                                            </th>
                                                            <th className="oom-page-results__details_results_table_heading_item oom-column-30">
                                                                <span>History</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="oom-page-results__details_results_items">
                                                        {currentItems.map((keyword, index) => {
                                                            const monthlySearchVolumes = keyword.keyword_idea_metrics?.monthly_search_volumes || [];

                                                            // Only return JSX if keyword.text !== inputKeyword, otherwise return null
                                                            if (keyword.text === inputKeyword) {
                                                                return null; // This prevents rendering when the condition is met
                                                            }

                                                            return (
                                                                <tr className='oom-page-results__details_results_table_item' key={index}>
                                                                    <td className='column-item oom-column-50'>
                                                                        <div className="keyword-input">
                                                                            <div className='keyword'>
                                                                                <span>{keyword.text}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className='column-item oom-column-20'>
                                                                        <div className='search-volume'>
                                                                            <span>
                                                                                {formatNumber(monthlySearchVolumes.slice(-1)[0]?.monthly_searches) || "..."}
                                                                            </span>
                                                                        </div>
                                                                    </td>

                                                                    <td className='column-item oom-column-30'>
                                                                        <div className='history'>
                                                                            {monthlySearchVolumes.length > 0 && (
                                                                                <MonthlySearchChart
                                                                                    changeDisplay={false}
                                                                                    monthlySearchVolumes={monthlySearchVolumes}
                                                                                    onClick={() => handleOpenModal(monthlySearchVolumes)} // Pass data to modal
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="keywords-oveview">

                        </div>

                        <div className="serp-analysis">
                            <div className="serp-analysis__results">
                                <div className="search-results">
                                    <div className="oom-page-results">
                                        <div className='oom-page-results__details'>
                                            <div className="oom-page-results__details_results">

                                                <div className="oom-page-results__details_results_heading">
                                                    <span>SERP Analysis</span>
                                                </div>

                                                {!searchResultsLoading && searchResults?.length > 0 && (
                                                    <div className="oom-page-results__details_results_sub_heading">
                                                        <span>SERP Features</span>
                                                        <div className="serp-features-icon">
                                                            {serpFeatures.reviews && (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <strong>Reviews</strong>
                                                                            <p>Organic search results that display star ratings along with the number of reviews.</p>
                                                                        </>
                                                                    }
                                                                    className="tooltip-icon"
                                                                    placement="top-start"
                                                                    followCursor
                                                                >
                                                                    <span><StarBorderIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                                </Tooltip>
                                                            )}

                                                            {serpFeatures.people_also_ask && (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <strong>People also ask</strong>
                                                                            <p>A set of expandable question boxes located between the organic search results.</p>
                                                                        </>
                                                                    }
                                                                    className="tooltip-icon"
                                                                    placement="top-start"
                                                                    followCursor
                                                                >
                                                                    <span><HelpCenterIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                                </Tooltip>
                                                            )}

                                                            {serpFeatures.sitelinks && (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <strong>Sitelinks</strong>
                                                                            <p>A collection of links to other pages within a website, displayed beneath the primary organic search result, typically for brand-specific search queries.</p>
                                                                        </>
                                                                    }
                                                                    className="tooltip-icon"
                                                                    placement="top-start"
                                                                    followCursor
                                                                >
                                                                    <span><LinkIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                                </Tooltip>
                                                            )}

                                                            {serpFeatures.local_pack && (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <strong>Localpack</strong>
                                                                            <p>A group of three local businesses, often with maps, shown for location-based searches.</p>
                                                                        </>
                                                                    }
                                                                    className="tooltip-icon"
                                                                    placement="top-start"
                                                                    followCursor
                                                                >
                                                                    <span><PlaceIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                                </Tooltip>
                                                            )}

                                                            {serpFeatures.business_map && (
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <strong>Google Business Map</strong>
                                                                            <p>A section on the right side of the SERP displaying information from the company's Google Business Profile. This may include details such as the business description, operating hours, phone number, address, and more.</p>
                                                                        </>
                                                                    }
                                                                    className="tooltip-icon"
                                                                    placement="top-start"
                                                                    followCursor
                                                                >
                                                                    <span><StorefrontIcon sx={{ fontSize: 18 }} className="tooltip-icon" /></span>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {searchResultsLoading && (
                                                    <Loader />
                                                )}

                                                {!searchResultsLoading && searchResults?.length > 0 && (
                                                    <div className="search-results">
                                                        <div className="oom-page-results__details_results_sub_heading"><span>Search Results</span></div>
                                                        <table className="oom-page-results__details_results_table">
                                                            <thead className="oom-page-results__details_results_table_heading">
                                                                <tr>
                                                                    <th className="oom-page-results__details_results_table_heading_item oom-column-5">
                                                                        <span>Position</span>
                                                                    </th>
                                                                    <th className="oom-page-results__details_results_table_heading_item oom-column-40">
                                                                        <span>Title</span>
                                                                    </th>
                                                                    <th className="oom-page-results__details_results_table_heading_item oom-column-50">
                                                                        <span>URL</span>
                                                                    </th>
                                                                    <th className="oom-page-results__details_results_table_heading_item oom-column-5">
                                                                        <span>URL Keywords</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="oom-page-results__details_results_items">
                                                                {searchResults && searchResults.length > 0 ? (
                                                                    searchResults.map((result) => (
                                                                        <tr className="oom-page-results__details_results_table_item" key={result.position}>
                                                                            <td className="column-item oom-column-5">
                                                                                <div className="position">
                                                                                    <span>{result.position}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td className="column-item oom-column-40">
                                                                                <div className="title">
                                                                                    <span>{result.title}</span>
                                                                                    <div className="serp-features-items">
                                                                                        {result.sitelinks.length > 0 && (
                                                                                            <div className="serp-feaures-item">
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        <>
                                                                                                            <strong>Sitelinks</strong>
                                                                                                            <p>A collection of links to other pages within a website, displayed beneath the primary organic search result, typically for brand-specific search queries.</p>
                                                                                                        </>
                                                                                                    }
                                                                                                    className="tooltip-container"
                                                                                                    placement="top-start"
                                                                                                    followCursor>
                                                                                                    <span><LinkIcon sx={{ fontSize: 14 }} className="tooltip-icon" />Sitelinks</span>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        )}
                                                                                        {result.reviews && result.reviews !== "No reviews available" && (
                                                                                            <div className="serp-feaures-item">
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        <>
                                                                                                            <strong>Reviews</strong>
                                                                                                            <p>Organic search results that display star ratings along with the number of reviews.</p>
                                                                                                        </>
                                                                                                    }
                                                                                                    className="tooltip-container"
                                                                                                    placement="top-start"
                                                                                                    followCursor>
                                                                                                    <span><StarBorderIcon sx={{ fontSize: 14 }} className="tooltip-icon" />Reviews</span>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="column-item oom-column-50">
                                                                                <div className="url">
                                                                                    <span>{result.url}</span>
                                                                                    <a href={result.url} target="_blank" rel="noopener noreferrer"><OpenInNewIcon sx={{ fontSize: 14 }} /></a>
                                                                                </div>
                                                                            </td>
                                                                            <td className="column-item oom-column-5">
                                                                                <div className="position">
                                                                                    <span>10</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="4" className="text-center">No results found</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}

                                                {!peopleAlsoAskLoading && peopleAlsoAsk?.length > 0 && (
                                                    <div className="people-also-ask">
                                                        <div className="oom-page-results__details_results_sub_heading">
                                                            <span>People also ask</span>
                                                        </div>
                                                        <table className="oom-page-results__details_results_table">
                                                            <thead className="oom-page-results__details_results_table_heading">
                                                                <tr>
                                                                    <th className="oom-page-results__details_results_table_heading_item oom-column-50">
                                                                        <span>Question</span>
                                                                    </th>
                                                                    <th className="oom-page-results__details_results_table_heading_item oom-column-50">
                                                                        <span>Answer</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="oom-page-results__details_results_items">
                                                                {peopleAlsoAsk && peopleAlsoAsk.length > 0 ? (
                                                                    peopleAlsoAsk.map((ask, index) => (
                                                                        <tr className='oom-page-results__details_results_table_item' key={index}>
                                                                            <td className="column-item oom-column-50">
                                                                                <div className="question">
                                                                                    <span>{ask.question}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td className="column-item oom-column-50">
                                                                                <div className="answer url">
                                                                                    <span dangerouslySetInnerHTML={{ __html: ask.answer }} />
                                                                                    {ask.url && (<a href={ask.url} target="_blank" rel="noopener noreferrer"><OpenInNewIcon sx={{ fontSize: 14 }} /></a>)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <Dialog open={monthlySearchVolumesModal} onClose={handleCloseModal}>
                            <DialogTitle>Monthly Search Volume</DialogTitle>
                            <DialogContent onClick={(e) => e.stopPropagation()}>
                                {selectedChartData ? (
                                    <MonthlySearchChart monthlySearchVolumes={selectedChartData} changeDisplay={true} />
                                ) : (
                                    <p>Loading chart...</p>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseModal} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>

        </React.Fragment>
    );
};
