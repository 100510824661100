/**
 * Keyword Manager
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */


import React from 'react';

export default function KeywordManager() {
    return (
        <React.Fragment>
            <div className="oom-page-attributes breadcrumb projects">
                <p className="oom-page-attributes_breadcrumb">Research / <span className="highlight">Keyword Manager</span></p>
                <p className="oom-page-attributes_title">Research <span className="highlight">/ Keyword Manager</span></p>
            </div>

            <div className="oom-form coming-soon">
                <div className="oom-form_coming_soon_wapper">
                    <span className="oom-form_coming_soon_title">We're Building Something Amazing!</span>
                    <svg width="163" height="153" viewBox="0 0 163 153" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M113.382 85.9219L96.0581 103.246L145.027 152.215L162.351 134.891L113.382 85.9219Z" fill="url(#paint0_linear_3378_35399)"/>
                        <path d="M126.416 61.009C142.178 61.009 154.999 48.1873 154.999 32.4257C154.999 27.689 153.693 23.279 151.651 19.359L129.601 41.409L117.433 29.2407L139.483 7.19065C135.563 5.14898 131.153 3.84232 126.416 3.84232C110.654 3.84232 97.8327 16.664 97.8327 32.4257C97.8327 35.774 98.4861 38.959 99.5478 41.899L84.4394 57.0073L69.9027 42.4706L75.7011 36.6723L64.1861 25.1573L81.4994 7.84398C71.9444 -1.71102 56.4278 -1.71102 46.8728 7.84398L17.9627 36.754L29.4778 48.269H6.44775L0.649414 54.0673L29.5594 82.9773L35.3577 77.179V54.0673L46.8728 65.5823L52.6711 59.784L67.2077 74.3207L6.69275 134.836L24.0061 152.149L116.943 59.294C119.883 60.3557 123.068 61.009 126.416 61.009Z" fill="url(#paint1_linear_3378_35399)"/>
                        <defs>
                        <linearGradient id="paint0_linear_3378_35399" x1="81.5002" y1="0.677734" x2="81.5002" y2="152.215" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3D59D0"/>
                        <stop offset="1" stop-color="#2D80FE"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_3378_35399" x1="81.5002" y1="0.677734" x2="81.5002" y2="152.215" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3D59D0"/>
                        <stop offset="1" stop-color="#2D80FE"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <span className="oom-form_coming_soon_desc">This feature is still under development and will be ready soon. In the meantime, stay tuned for exciting updates! <br/>Thanks for your patience.</span>
                </div>
            </div>

        </React.Fragment>
    );
};