/**
 * Dashboard
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */

import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useAuthHeaders } from "../../context/AuthContext";
import useAuditWebsites from "../../hooks/useAuditWebsites";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/formatDate";
import AddIcon from "@mui/icons-material/Add";
import AssessmentSharpIcon from '@mui/icons-material/AssessmentSharp';
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";

export default function AuditWebsites({ page, subpage, id }) {
  const navigate = useNavigate();
  const headers = useAuthHeaders();
  const { auditWebsites, error } = useAuditWebsites(headers);
  const toastRef = useRef(false);

  const [selectedWebsiteId, setSelectedWebsiteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModelOpen] = useState(false);

  useEffect(() => {
    if (error && !toastRef.current) {
      toast.error(error);
    }
  }, [error]);

  const handleNewAudit = () => {
    navigate("/audit/new/");
  };

  const handleAddToProjectModal = (websiteId) => {
    setModelOpen(true);
    setSelectedWebsiteId(websiteId);
  };

  const handleAddToProject = async () => {
    if (!selectedWebsiteId) {
        toast.error("No website selected.");
        return;
    }

    setLoading(true);

    try {
        const endPointAuditAddToProject = process.env.REACT_APP_OOM_SEO_API_AUDIT_ADD_TO_PROJECT;
        const response = await axios.post(
            `${endPointAuditAddToProject}`,
            {
                website_id: selectedWebsiteId,
            },
            { headers }
        );

        if (response.status === 201) {
            console.log('Success:', response.data.message);
            toast.success(response.data.message);
        } else {
            console.log('Unexpected Response:', response.data);
            toast.error('Unexpected Response: Please contact Administrator');
        }
    } catch (error) {
        console.error('Error adding audit website to project:', error.response?.data || error.message);
        toast.error('Server Error: Please contact Administrator');
    } finally {
        setLoading(false);
        setModelOpen(false);
    }
  };

  const handleCloseModal = () => {
    setModelOpen(false);
  };

  return (
    <React.Fragment>
      <div className="oom-page-header">
        <div className="oom-page-attributes breadcrumb audit-websites">
          <p className="oom-page-attributes_breadcrumb">
            {id ? "Audit Website" : "Audit Websites"} /{" "}
            <span className="highlight">All Websites </span>
          </p>
          <p className="oom-page-attributes_title">
            Audit /{" "}
            <a href="/projects/">
              <span className="highlight">All Websites</span>
            </a>{" "}
            {id && id !== "websites" ? `/ ${id}` : ""}
          </p>
        </div>
      </div>

      <div className="oom-page-content">
        {id && id === "websites" && (
          <div className="oom-page-results projects">
            <div className="oom-page-results__details">
              <div className="oom-page-results__details_heading">
                <div className="oom-page-results__details_heading_button">
                  <button
                    onClick={handleNewAudit}
                    title="Audit New Website"
                    className="oom-button plain"
                  >
                    <span className="text">
                      <TroubleshootIcon sx={{ fontSize: 14 }} /> Audit New Website
                    </span>
                  </button>
                </div>
              </div>

              <div className="oom-page-results__details_results">
                <div className="oom-page-results__details_results_heading">
                  <span>List of Websites</span>
                </div>

                <table className="oom-page-results__details_results_table">
                  <thead className="oom-page-results__details_results_table_heading">
                    <tr>
                      <th className="oom-page-results__details_results_table_heading_item oom-column-20">
                        <span>Audit Name</span>
                      </th>
                      <th className="oom-page-results__details_results_table_heading_item oom-column-20">
                        <span>Audit URL</span>
                      </th>
                      <th className="oom-page-results__details_results_table_heading_item oom-column-10">
                        <span>Audit by</span>
                      </th>
                      <th className="oom-page-results__details_results_table_heading_item oom-column-10">
                        <span>Date Audit</span>
                      </th>
                      <th className="oom-page-results__details_results_table_heading_item oom-column-10">
                        <span>Date Updated</span>
                      </th>
                      <th className="oom-page-results__details_results_table_heading_item oom-column-20">
                        <span>Assigned Users</span>
                      </th>
                      <th className="oom-page-results__details_results_table_heading_item oom-column-10">
                        <span>Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="oom-page-results__details_results_items">
                    {auditWebsites.length > 0 ? (
                      auditWebsites.map((auditWebsite, index) => (
                        <tr
                          className="oom-page-results__details_results_table_item"
                          key={index}
                        >
                          <td className="column-item oom-column-20">
                            <div className="name">
                              <a
                                href={`results/${auditWebsite._id}`}
                                className="link-underline"
                              >
                                <span>{auditWebsite.auditName}</span>
                              </a>
                            </div>
                          </td>

                          <td className="column-item oom-column-20">
                            <div className="url">
                              <a
                                href={`${auditWebsite.auditUrl}`}
                                target="_blank" rel="noopener noreferrer"
                                className="link-underline"
                              >
                                <span>{auditWebsite.auditUrl}</span>
                              </a>
                            </div>
                          </td>
                          <td className="column-item oom-column-10">
                            <div className="audit-by">
                              <span>{auditWebsite.createdBy.displayName}</span>
                            </div>
                          </td>
                          <td className="column-item oom-column-10">
                            <div className="created-at">
                              <span>{formatDate(auditWebsite.createdAt)}</span>
                            </div>
                          </td>

                          <td className="column-item oom-column-10">
                            <div className="updated-at">
                              {auditWebsite.createdAt !== auditWebsite.updatedAt ? (
                                  <span>{formatDate(auditWebsite.updatedAt)}</span>
                              ) : (
                                  <span></span> 
                              )}
                            </div>
                          </td>

                          <td className="column-item oom-column-20">
                            <div className="users">
                              <span></span>
                            </div>
                          </td>

                          <td className="column-item oom-column-20">
                            <div className="actions">
                              <button
                                onClick={() => handleAddToProjectModal(auditWebsite._id)}
                                title="Add to My Project"
                                className="oom-button action icon"
                                disable={loading}
                              >
                                <AddIcon sx={{ fontSize: 18 }} />
                              </button>
                              <a
                                href={`results/${auditWebsite._id}`}
                                title="View Audit Results"
                                className="oom-button action icon"
                              >
                                <AssessmentSharpIcon sx={{ fontSize: 18 }} />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7} className="no-found">
                          <p>No audit website found</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <Dialog
              open={modalOpen}
              onClose={handleCloseModal}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Add to My Project</DialogTitle>
              <DialogActions>
                <Button
                  onClick={handleAddToProject}
                  color="secondary"
                  className="oom-button oom-btn-loader action"
                >
                  <span className="text">Save</span>
                </Button>
                <Button
                  onClick={handleCloseModal}
                  color="secondary"
                  className="oom-button oom-btn-loader action"
                >
                  <span className="text">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
