/**
 * Project New
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */


import React, { useState } from 'react';
import axios from 'axios';
import { useAuthHeaders } from '../../context/AuthContext';
import { formatUrl } from '../../utils/formatUrl';
import { toast } from 'react-toastify';

import "../../assets/css/Projects.css";

export default function ProjectCreate() {
    const [projectUrl, setProjectUrl] = useState('');
    const [projectName, setProjectName] = useState('');
    const [loading, setLoading] = useState(false);
    const headers = useAuthHeaders();

    const handleSaveProject = async () => {
        if (!projectUrl) {
            toast.error("Please enter a domain e.g https://");
            return;
        }

        if (!projectName) {
            toast.error("Please enter Audit Name");
            return;
        }

        setLoading(true);
        try {
            const endPointProject = process.env.REACT_APP_OOM_SEO_API_PROJECTS;
            const response = await axios.post(
                endPointProject,
                {
                    project_url: projectUrl,
                    project_name: projectName,
                },
                { headers }
            );
    
            if (response.data && response.status === 201) {
                console.log('Response from server:', response.data);
                toast.error(`${response.data.message}`);
            } 
            
        } catch (error) {
            console.error('Error starting scan:', error);
            if (error.response) {
                console.log('Error response:', error.response);
                const message = error.response.data?.message || 'An error occurred';
                toast.error(`${message}`);
            } else {
                toast.error('An error occurred, please contact the administrator.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="oom-page-header">
                <div className="oom-page-attributes breadcrumb projects">
                    <p className="oom-page-attributes_breadcrumb">Projects / <span className="highlight">Create Project</span></p>
                    <p className="oom-page-attributes_title">Projects <span className="highlight">/ Create Project</span></p>
                </div>
            </div>
            
            <div className="oom-page-content">
                <div className="oom-form project-form">
                    <p className="oom-form_title">Set up Project</p>
                    
                    <div className="oom-field-container">
                        <input
                            type="text"
                            className="oom-field url"
                            value={projectUrl}
                            onChange={(e) => setProjectUrl(formatUrl(e.target.value))}
                            placeholder="Enter a domain name e.g https://"
                            disabled={loading}
                        />
                    </div>

                    <div className="oom-field-container">
                        <input
                            type="text"
                            className="oom-field"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            placeholder="Project Name"
                            disabled={loading}
                        />
                    </div>

                    <button onClick={handleSaveProject} title="Audit" className='oom-button oom-btn-loader action' disabled={loading}>
                        {loading ? 'Loading...' : 'Submit'}
                    </button>

                </div>
            </div>
        </React.Fragment>
    );
};