import React from "react";

const StatusItem = ({ title, number, color }) => {
  return (
    <div className="status-item">
      <h4 className="status-item-title" style={{ color: color }}>
        {title}
      </h4>
      <div>
        <span className="status-item-number" style={{ color: color }}>
          {number}
        </span>
        <span className="status-item-text">no changes</span>
      </div>
      <div className="status-item-group">
        <div className="status-item-line-wapper">
          <span className="status-item-line-number">0</span>
          <span className="status-item-line gray-line"></span>
        </div>
        <div className="status-item-line-wapper" style={{ color: color }}>
          <span className="status-item-line-number">{number}</span>
          <span className="status-item-line" style={{ backgroundColor: color }}></span>
        </div>
      </div>
    </div>
  );
};

const StatusOverview = ({ data }) => {
  return (
    <div className="status-overview">
      {data.map((item, index) => (
        <StatusItem
          key={index}
          title={item.title}
          number={item.number}
          color={item.color}
        />
      ))}
    </div>
  );
};

export default StatusOverview;
