import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SectionSideBarMenu from '../components/template/SectionSideBarMenu';
import PageController from '../controllers/PageController';
import 'react-toastify/dist/ReactToastify.css'; 
import "../assets/css/Main.css";
import "../assets/css/Loader.css";

const menuLabels = {
    'dashboard': 'Dashboard',
    'projects': 'Projects',
    'research': 'Research',
    'content-generation': 'Content Generation',
    'website-audit': 'Website Audit',
};

export default function DynamicPage() {
    const { page, subpage, id } = useParams();
    const [profile, setProfile] = useState(null); 
    const PageContent = PageController.getPageContent(page, subpage, id, profile);

    const [expanded, setExpanded] = useState(null);
    const [collapse, setCollapse] = useState(null);
    const [expandedMenu, setExpandedMenu] = useState(null);
    const [activeTitle, setActiveTitle] = useState(null);
    const [isActive, setIsActive] = useState(false);

    const handleMouseEnter = (menuName, title) => {
        setExpanded(true);
        // setCollapse(true);
        setExpandedMenu(menuName);
        setActiveTitle(title);
    };

    const handleMouseLeave = () => {
        setExpanded(false);
        setCollapse(false);
        setExpandedMenu(null);
        setActiveTitle(null);
    };

    const toggleAccordion = () => {
        setIsActive((prevState) => !prevState);
    };

    const fetchProfile = async () => {
        const token = localStorage.getItem('oom-access-token'); 
        if (token) {
            try {
                const response = await axios.get(process.env.REACT_APP_OOM_SEO_API_USER_PROFILE, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.data.status === 200) {
                    setProfile(response.data); 
                } else {
                    console.error('Failed to fetch profile:', response.data);
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        }
    };

    useEffect(() => {
        fetchProfile(); 
    }, []);

    return (
        <React.Fragment>
            <SectionSideBarMenu handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} expanded={expanded} toggleAccordion={toggleAccordion} expandedMenu={expandedMenu} activeTitle={activeTitle} menuLabels={menuLabels} isActive={isActive} />
            <section className={`oom-main-content-section ${collapse ? 'collapse' : ''}`}>
                 <ToastContainer
                    position="top-right"
                    autoClose={3000} 
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="oom-page-container">
                    <PageContent page={page} subpage={subpage} id={id} profile={profile}/>
                </div>
            </section>
        </React.Fragment>
    )
}