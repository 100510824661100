import React from 'react';

// Sample data based on your summary
const pageSummary = {
  healthy: 2,
  broken: 0,
  haveIssues: 6,
  redirects: 2,
  blocked: 0,
};

const MultiProgressBar = () => {
  const total = pageSummary.healthy + pageSummary.broken + pageSummary.haveIssues + pageSummary.redirects + pageSummary.blocked;

  const healthyPercent = total ? (pageSummary.healthy / total) * 100 : 0;
  const brokenPercent = total ? (pageSummary.broken / total) * 100 : 0;
  const haveIssuesPercent = total ? (pageSummary.haveIssues / total) * 100 : 0;
  const redirectsPercent = total ? (pageSummary.redirects / total) * 100 : 0;
  const blockedPercent = total ? (pageSummary.blocked / total) * 100 : 0;

  return (
    <div className="multicolor-progressbar-wrapper">
      <div className='multicolor-progressbar' style={{ width: '100%', position: 'relative' }}>
        <div style={{ width: `${healthyPercent}%`, backgroundColor: '#1B9C81', height: '20px' }} />
        <div style={{ width: `${brokenPercent}%`, backgroundColor: '#C2253E', height: '20px' }} />
        <div style={{ width: `${haveIssuesPercent}%`, backgroundColor: '#FFB31F', height: '20px' }} />
        <div style={{ width: `${redirectsPercent}%`, backgroundColor: '#2C80FF', height: '20px' }} />
        <div style={{ width: `${blockedPercent}%`, backgroundColor: '#C9C9C9', height: '20px' }} />
      </div>
      <ul className='multicolor-summary'>
        <li><span className='summary-dot' style={{ backgroundColor: '#1B9C81' }}></span><span className='summary-title'>Healthy <span className="number">{pageSummary.healthy}</span></span></li>
        <li><span className='summary-dot' style={{ backgroundColor: '#C2253E' }}></span><span className='summary-title'>Broken <span className="number">{pageSummary.broken}</span></span></li>
        <li><span className='summary-dot' style={{ backgroundColor: '#FFB31F' }}></span><span className='summary-title'>Have Issues <span className="number">{pageSummary.haveIssues}</span></span></li>
        <li><span className='summary-dot' style={{ backgroundColor: '#2C80FF' }}></span><span className='summary-title'>Redirects <span className="number">{pageSummary.redirects}</span></span></li>
        <li><span className='summary-dot' style={{ backgroundColor: '#C9C9C9' }}></span><span className='summary-title'>Blocked <span className="number">{pageSummary.blocked}</span></span></li>
      </ul>
    </div>
  );
};

export default MultiProgressBar;