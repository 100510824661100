/**
 * Normalizes a given URL by removing the protocol (`http://`, `https://`) and the `www.` prefix.
 *
 * This function ensures that a URL is in a consistent format without protocols and `www.` prefixes, 
 * returning only the hostname (domain name) part of the URL.
 * If the URL is invalid, it returns an empty string (`''`).
 *
 * @since 1.0.0
 * @version 1.0.0
 * 
 * @package URLUtilities
 * 
 * @author OOm Developer (oom_ss)
 *
 * @example
 * const normalizedUrl1 = normalizeUrl("https://www.example.com");
 * console.log(normalizedUrl1); // Outputs: "example.com"
 *
 * const normalizedUrl2 = normalizeUrl("http://example.com");
 * console.log(normalizedUrl2); // Outputs: "example.com"
 *
 * const normalizedUrl3 = normalizeUrl("www.example.com");
 * console.log(normalizedUrl3); // Outputs: "example.com"
 *
 * const normalizedUrl4 = normalizeUrl("invalid-url");
 * console.log(normalizedUrl4); // Outputs: ""
 *
 * @param {string} url - The URL to be normalized. This can include or omit the protocol (`http://`, `https://`) 
 *                        and may or may not contain the `www.` prefix.
 * 
 * @returns {string} The normalized hostname (domain name) without the protocol or `www.` prefix. 
 *                   Returns an empty string (`''`) if the URL is invalid.
 * 
 * @throws {Error} If the input URL cannot be parsed correctly (e.g., an invalid or malformed URL).
 */

export const normalizeUrl = (url) => {
    try {
        // Ensure the URL is correctly formatted by adding 'http://' if no protocol is present
        const formattedUrl = new URL(url.startsWith('http') ? url : `http://${url}`).hostname.replace(/^www\./, '');
        return formattedUrl;
    } catch (error) {
        // Return an empty string or handle invalid URL here
        return '';
    }
};