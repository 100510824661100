
/**
 * Determines the description of a given search intent.
 *
 * This function takes a search intent (such as 'navigational', 'informational', etc.) 
 * and returns a corresponding description that explains the type of intent. 
 * It helps categorize search behavior, commonly used in SEO or content strategy.
 *
 * @since 1.0.0
 * @version 1.0.0
 *
 * @package SearchUtilities
 * 
 * @author OOm Developer (oom_ss)
 *
 * @example
 * const intentDescription = searchIntent("navigational");
 * console.log(intentDescription); // Outputs: "The user wants to visit a specific website or page."
 *
 * @param {string} intent - The search intent type (e.g., "navigational", "informational", "transactional", "commercial investigation").
 * @returns {string} The description corresponding to the given search intent.
 * 
 * @throws {TypeError} If the input is not a valid string.
 */

export const searchIntent = (intent) => {
    let description;
  
    switch (intent.toLowerCase()) {
      case 'navigational':
        description = 'The user wants to visit a specific website or page.';
        break;
      case 'informational':
        description = 'The user is seeking information on a topic.';
        break;
      case 'transactional':
        description = 'The user intends to make a purchase or take an action.';
        break;
      case 'commercial investigation':
        description = 'The user is researching products or services to make a future purchase.';
        break;
      default:
        description = 'Search intent type not recognized.';
    }
  
    return description;
  };