/**
 * Custom Hook to Fetch Audit Website Data
 *
 * This hook retrieves a list of audit websites from an API and handles the loading, error, and data states.
 * It makes an asynchronous request to fetch audit website details. If the response indicates a lack of permissions,
 * it sets the error state with the message from the API. Otherwise, it returns the list of audit websites.
 *
 * @since 1.0.0
 * @version 1.0.0
 *
 * @package OOmAISEOTools
 * @package react-hooks
 * 
 * @author OOm Developer (oom_ss)
 *
 * @example
 * const { auditWebsites, loading, error } = useAuditWebsites(headers);
 * // `auditWebsites` will contain the list of audit websites fetched from the API.
 * // `loading` is `true` while fetching data.
 * // `error` will contain any error message if the request fails or if there are no permissions.
 */

import { useState, useEffect } from 'react';
import axios from 'axios';

const useAuditWebsites = (headers, refreshTrigger) => {
    // State to store the list of audit websites fetched from the API
    const [auditWebsites, setAuditWebsites] = useState([]);
    
    // State to track the loading status while the data is being fetched
    const [loading, setLoading] = useState(false);
    
    // State to store any error message encountered during the fetch process
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to fetch audit websites from the API
        const fetchAuditWebsites = async () => {
            const endPointAuditWebsites = process.env.REACT_APP_OOM_SEO_API_AUDIT_WEBSITES;
            setLoading(true); // Start loading state
            try {
                // Making the API request to fetch audit websites
                const response = await axios.get(endPointAuditWebsites, {
                    headers, // Passing custom headers for the API request
                });

                // Check if the API response indicates the user has permission to access audit websites
                if (response.data.has_permission !== false) {
                    // If permission exists, store the fetched audit websites in state
                    setAuditWebsites(response.data.audit_websites);
                } else {
                    // If no permission, clear the list and set the error message
                    setAuditWebsites([]);
                    setError(response.data.message);
                }
            } catch (err) {
                // Handle any errors that occur during the request
                setError('Error retrieving audit websites');
                console.error('Error:', err);
            } finally {
                // Ensure the loading state is set to false once the request completes
                setLoading(false);
            }
        };

        // Invoke the fetch function
        fetchAuditWebsites();
    }, [headers]); // Dependency on headers ensures the effect is re-run when headers change

    // Return the state values: auditWebsites, loading, and error
    return { auditWebsites, loading, error };
};

export default useAuditWebsites;
