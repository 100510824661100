/**
 * Audit Page
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useAuthHeaders } from '../../context/AuthContext';
import { formatUrl } from '../../utils/formatUrl';
import { toast } from 'react-toastify';

import Loader from '../../components/elements/Loader';
import UserAgent from '../../components/widgets/UserAgent';

import { Select, MenuItem } from '@mui/material';

import "../../assets/css/AuditForm.css";

const endPointServer = process.env.REACT_APP_OOM_SEO_API_SERVER || 'https://seocloud.oomgroup.com.sg:3002';
const socket = io(`${endPointServer}`, {
    transports: ['websocket', 'polling'],
    timeout: 10000  
});

export default function AuditPage() {
    const [selectedUserAgent, setSelectedUserAgent] = useState('Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36');
    const [auditUrl, setAuditUrl] = useState('');
    const [auditName, setAuditName] = useState('');
    const [forceRescan, setForceRescan] = useState('no');
    const [messages, setMessages] = useState([]);
    const [isScanning, setIsScanning] = useState(false);
    const [socketId, setSocketId] = useState('');
    const navigate = useNavigate();
    const headers = useAuthHeaders();

    useEffect(() => {
        socket.on('connect', () => {
            setSocketId(socket.id);
            console.log('Connected to socket with ID:', socket.id); // Log the socket ID
        });

        socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
            toast.error('Failed to connect to WebSocket server');
        });
    
        socket.on('connect_timeout', () => {
            console.error('Socket connection timeout');
            toast.error('WebSocket connection timed out');
        });

        // Listeners for messages specific to this socket
        socket.on('startScanning', (data) => {
            console.log('start');
            console.log(data);
            if (data.socketId === socketId) {
                setMessages((prev) => [...prev, data.message]);
                setIsScanning(true);
            }
        });

        // Listeners for messages specific to this socket
        socket.on('urlsSaved', (data) => {
            if (data.socketId === socketId) {
                setMessages((prev) => [...prev, data.message]);
                setIsScanning(true);
            }
        });

        socket.on('fetchedPageData', (data) => {
            if (data.socketId === socketId) {
                setMessages((prev) => [...prev, `${data.message}`]);
            }
        });

        socket.on('scanCompleted', (data) => {
            console.log(data);
            if (data.socketId === socketId) {
                setMessages((prev) => [...prev, data.message]);
                setIsScanning(false);
                navigate(`/audit/results/${data.websiteId}`);
            }
        });

        return () => {
            socket.off('startScanning');
            socket.off('urlsSaved');
            socket.off('fetchedPageData');
            socket.off('scanCompleted');
        };
    }, [navigate, socketId]);

    const handleScan = async () => {
        if (!auditUrl) {
            toast.error("Please enter a domain e.g https://");
            return;
        }

        if (!auditName) {
            toast.error("Please enter Audit Name");
            return;
        }
       
        if (!forceRescan) {
            toast.error("Select If force rescan or not?");
            return;
        }

        console.log('Attempting to scan URL:', auditUrl); // Log the URL
        console.log('Using Socket ID:', socketId); // Log the socket ID
        setIsScanning(true);
        setMessages([]);

        const refreshToken = localStorage.getItem('oom-refresh-token');

        try {
            const endPointAudit = process.env.REACT_APP_OOM_SEO_API_AUDIT_WEBSITE;
            const response = await axios.post(
                `${endPointAudit}`,
                {
                    audit_url: auditUrl,
                    audit_name: auditName,
                    user_agent: selectedUserAgent,
                    force_rescan: forceRescan,
                    socket_id: socketId,
                },
                { headers }
            );

            if (response.data && response.status === 200) {
                console.log('Response from server:', response.data);
                setMessages((prev) => [...prev, 'Scanning started...']);
            }

        } catch (error) {
            console.error('Error starting scan:', error);
            if (error.response && error.response.status === 401) {
                // Handle expired token: Try refreshing the token
                const newAccessToken = await refreshAccessToken(refreshToken);
                if (newAccessToken) {
                    // Retry the original request with the new access token
                    return handleScan(); // Recursive call to retry scan
                } else {
                    setMessages((prev) => [...prev, 'Session expired, please log in again.']);
                    navigate('/login');  // Redirect to login page
                }
            } else {
                setMessages((prev) => [...prev, 'Error starting scan']);
            }
        }
    };

    const refreshAccessToken = async (refreshToken) => {
        try {
            const endPointRefreshToken = process.env.REACT_APP_OOM_SEO_API_USER_REFRESH_TOKEN;
            const response = await axios.post(
                `${endPointRefreshToken}`,
                { refresh_token: refreshToken }
            );
            const newAccessToken = response.data.accessToken;
            if (newAccessToken) {
                localStorage.setItem('oom-access-token', newAccessToken);  // Store the new access token
                return newAccessToken;
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            return null;
        }
    };

    return (
        <React.Fragment>      
            <div className="oom-page-header"> 
                <div className="oom-page-attributes breadcrumb audit">
                    <p className="oom-page-attributes_breadcrumb">Website Audit / <span className="highlight">New Audit</span></p>
                    <p className="oom-page-attributes_title">
                        Website Audit / <span className="highlight"> New Audit</span>
                    </p>
                </div>
            </div>     

            <div className="oom-page-content"> 
                <div className="oom-form audit-form">
                    <p className="oom-form_title">Website Audit</p>
                    <small>Analyze your website or a client's website and get recommendations for performance improvements.</small>

                    <UserAgent setSelectedUserAgent={setSelectedUserAgent} isDisable={isScanning}/>

                    <div className="oom-field-container">
                        <input
                            type="text"
                            className="oom-field"
                            value={auditName}
                            onChange={(e) => setAuditName(e.target.value)}
                            placeholder="Audit Name"
                            disabled={isScanning}
                        />
                    </div>

                    <div className="oom-field-container">
                        <input
                            type="text"
                            className="oom-field url"
                            value={auditUrl}
                            onChange={(e) => setAuditUrl(formatUrl(e.target.value))}
                            placeholder="Enter a domain name e.g https://"
                            disabled={isScanning}
                        />
                    </div>

                    <div className="oom-field-container oom-custom-select-container">
                        <Select
                            value={forceRescan}
                            onChange={(e) => setForceRescan(e.target.value)}
                            disabled={isScanning}
                            displayEmpty
                            fullWidth
                        >
                            <MenuItem value="" disabled>Force Rescan</MenuItem>
                            <MenuItem key="1" value="no">No</MenuItem>
                            <MenuItem key="2" value="yes">Yes</MenuItem>
                        </Select>
                    </div>
                    
                    <button onClick={handleScan} title="Audit" className='oom-button oom-btn-loader action' disabled={isScanning}>
                        {isScanning ? 'Scanning...' : 'Audit'}
                    </button>

                    <div className="oom-audit-progress">
                        {isScanning && (
                            <Loader />
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
