import React from 'react';

const ProgressBar = ({ label, value, max, unit, change, changeLabel }) => {
  const percentage = (value / max) * 100;

  return (
    <div className='progressbar-item' style={{ width: '50%', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
      <div className="progressbar-wrapper">
        <div style={{ marginBottom: '10px' }}>
          <span className='progressbar-title'>{label}</span>
        </div>
        <div className='progressbar-text'>
          <span>{value}</span>
          {unit && <span> {unit}</span>}
          {change !== undefined && (
            <span style={{ color: change < 0 ? 'red' : 'green', marginLeft: '10px' }}>
              {change < 0 ? '▼' : '▲'} {Math.abs(change)}
            </span>
          )}
          {changeLabel && <span style={{ color: '#1B9C81'}}> {changeLabel}</span>}
        </div>
        <div style={{ position: 'relative', height: '10px', backgroundColor: '#f3f3f3', borderRadius: '10px' }}>
          <div className='progressbar'
            style={{
              width: `${percentage}%`,
              height: '100%',
              backgroundColor: 'rgba(54, 162, 235, 1)',
              borderRadius: '10px',
              transition: 'width 0.3s ease',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
