/**
 * Content Generation
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuthHeaders } from '../../context/AuthContext';
import useProjects from '../../hooks/useProjects';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, TextField, MenuItem } from '@mui/material';

import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

import AddBoxIcon from '@mui/icons-material/AddBox';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { renderContent } from '../../utils/renderContent';
import { formatNumber } from '../../utils/formatNumber';
import { capitalizeString } from '../../utils/capitalizeString';
import MonthlySearchChart from '../../components/widgets/MonthlySearchChart';
import Loader from '../../components/elements/Loader';
import AiGenerateIcon from '../../components/elements/AiGenerateIcon';

import "../../assets/css/ContentGeneration.css";

export default function ContentGeneration({ id }) {
    const server_uri = process.env.REACT_APP_OOM_SEO_API_SERVER;
    const endPointPostContent = process.env.REACT_APP_OOM_SEO_API_POST_CONTENT;
    const endPointGeneratedHistories = process.env.REACT_APP_OOM_SEO_API_GENERATED_HISTORIES;

    const navigate = useNavigate();
    const headers = useAuthHeaders();
    const { projects } = useProjects(headers);

    const [step, setStep] = useState(1);
    const [stepCurrentName, setStepCurrentName] = useState("Selecting Keywords");

    const [loading, setLoading] = useState(false);
    const [generatingTopic, setGeneratingTopic] = useState(false);
    const [generatingTopicImage, setGeneratingTopicImage] = useState(false);
    const [generatingPrimaryKeywords, setGeneratingPrimaryKeywords] = useState(false);
    const [generatingTopicContent, setGeneratingTopicContent] = useState(false);
    const [savingPostContent, setSavingPostContent] = useState(false);
    const [generatedPrimaryKeywords, setGeneratedPrimaryKeywords] = useState([]);
    const [generatedTopics, setGeneratedTopics] = useState([]);
    const [generatedTopicImage, setGeneratedTopicImage] = useState([]);
    const [generatedTopicContent, setGeneratedTopicContent] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState("");
    const [customKeywords, setCustomKeywords] = useState([]);
    const [inputKeyword, setInputKeyword] = useState("");
    const [targetAudience, setTargetAudience] = useState(["professionals"]);
    const [inputTargetAudience, setInputTargetAudience] = useState("");
    const [excludeTerms, setExcludeTerms] = useState([]);
    const [inputExcludeTerms, setInputExcludeTerms] = useState("");
    const [inputTextOutline, setInputTextOutline] = useState('');
    const [saveKeywords, setSaveKeywords] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedSaveKeywords, setSelectedSaveKeywords] = useState([]);
    const [selectedPrimaryKeywords, setSelectedPrimaryKeywords] = useState([]);
    const [currentPrimaryKeywords, setCurrentPrimaryKeywords] = useState("");
    const [currentTopics, setCurrentTopics] = useState("");
    const [currentTopicImage, setCurrentTopicImage] = useState("");
    const [currentTopicContent, setCurrentTopicContent] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("english");
    const [selectedContentLength, setSelectedContentLength] = useState("1000-1500");
    const [keywordData, setKeywordData] = useState({});
    const [selectedChartData, setSelectedChartData] = useState(null);
    const [postContent, setPostContent] = useState([]);
    const [postContentName, setPostContentName] = useState("");

    const [monthlySearchVolumesModal, setMonthlySearchVolumesModal] = useState(false);
    const [topicImagePromptModal, setTopicImagePromptModal] = useState(false);
    const [topicImagePrompt, setTopicImagePrompt] = useState("");

    useEffect(() => {
        const fetchPostContent = async () => {
            try {
                const response = await axios.get(
                    `${endPointPostContent}/${id}`,
                    {
                        headers,
                    }
                );

                if (response.data && response.status === 200) {
                    setPostContent(response.data);
                    const responseData = response.data.post;
                    const mainKeywords = responseData.mainKeywords.split(',').map(keyword => keyword.trim());
                    const additionalKeywords = responseData.additionalKeywords.split(',').map(keyword => keyword.trim());
                    let selectedPrimaryKeywords = [];
                    let targetAudienceText = [];
                    let excludeTermsText = [];

                    if (responseData.currentStep) {
                        setStep(responseData.currentStep);
                    }

                    if (responseData.selectedPrimaryKeywords && responseData.selectedPrimaryKeywords.trim() !== "") {
                        selectedPrimaryKeywords = responseData.selectedPrimaryKeywords.split(',').map(keyword => keyword.trim());
                    }
                    if (responseData.targetAudience && responseData.targetAudience.trim() !== "") {
                        targetAudienceText = responseData.targetAudience.split(',').map(keyword => keyword.trim());
                    }

                    if (responseData.excludeTerms && responseData.excludeTerms.trim() !== "") {
                        excludeTermsText = responseData.excludeTerms.split(',').map(keyword => keyword.trim());
                    }
                    setSelectedSaveKeywords(mainKeywords);
                    setSelectedProject(responseData.projectId);
                    setCurrentPrimaryKeywords(responseData.primaryKeywords);
                    setPostContentName(responseData.postName);
                    if (responseData.additionalKeywords !== "" && additionalKeywords.length > 0) {
                        setCustomKeywords([additionalKeywords]);
                    } else {
                        setCustomKeywords([]);
                    }

                    if (responseData.selectedPrimaryKeywords !== "" && selectedPrimaryKeywords.length > 0) {
                        setSelectedPrimaryKeywords(selectedPrimaryKeywords);
                        setSelectedTopic({ primary_keywords: selectedPrimaryKeywords, topic: responseData.selectedTopic, overview: responseData.selectedTopicOverview, });
                        setTopicImagePrompt(responseData.selectedTopic);
                    } else {
                        setSelectedPrimaryKeywords([]);
                        setSelectedTopic(null);
                    }

                    if (responseData.topics) {
                        setCurrentTopics(responseData.topics);
                    }

                    if (responseData.topicImage) {
                        setCurrentTopicImage(responseData.topicImage);
                    }

                    if (responseData.topicContent) {
                        setCurrentTopicContent(responseData.topicContent);
                    }

                    if (responseData.language) {
                        setSelectedLanguage(responseData.language);
                    }

                    if (responseData.contentOutline) {
                        setInputTextOutline(responseData.contentOutline);
                    }

                    if (responseData.contentLength) {
                        setSelectedContentLength(responseData.contentLength);
                    }

                    if (responseData.targetAudience !== "" && targetAudienceText.length > 0) {
                        setTargetAudience(targetAudienceText);
                    }

                    if (responseData.excludeTerms !== "" && excludeTermsText.length > 0) {
                        setExcludeTerms(excludeTermsText);
                    }
                } else {
                    // navigate('/ai-generation/new/');
                }
            } catch (err) {
                console.error(err);
                // navigate('/ai-generation/new/');
            }
        };

        if (id && id !== 'new') {
            fetchPostContent();
        }
    }, [endPointPostContent, headers, id, navigate]);

    useEffect(() => {
        const fetchGeneratedHistoryPrimaryKeywords = async () => {
            try {
                const response = await axios.get(
                    `${endPointGeneratedHistories}/${currentPrimaryKeywords}`,
                    {
                        headers,
                    }
                );
                if (response.data && response.status === 200) {
                    const parseResponse = JSON.parse(response.data.history.generatedContent);
                    if (response.data.history.associatedId === selectedProject) {
                        setGeneratedPrimaryKeywords(parseResponse);
                    } else {
                        setGeneratedPrimaryKeywords([]);
                    }
                }
            } catch (err) {
                // console.error(err);
            }
        };

        const fetchGeneratedHistoryTopics = async () => {
            try {
                const response = await axios.get(
                    `${endPointGeneratedHistories}/${currentTopics}`,
                    {
                        headers,
                    }
                );
                if (response.data && response.status === 200) {
                    const parseResponse = JSON.parse(response.data.history.generatedContent);
                    if (response.data.history.associatedId === selectedProject) {
                        setGeneratedTopics(parseResponse);
                    } else {
                        setGeneratedTopics([]);
                    }
                }
            } catch (err) {
                // console.error(err);
            }
        };

        const fetchGeneratedHistoryTopicImage = async () => {
            try {
                const response = await axios.get(
                    `${endPointGeneratedHistories}/${currentTopicImage}`,
                    {
                        headers,
                    }
                );

                if (response.data && response.status === 200) {
                    const parseResponse = response.data.history.generatedContent;
                    if (response.data.history.associatedId === selectedProject) {
                        setGeneratedTopicImage(parseResponse);
                    } else {
                        setGeneratedTopicImage([]);
                    }
                }
            } catch (err) {
                // console.error(err);
            }
        };

        const fetchGeneratedHistoryTopicContent = async () => {
            try {
                const response = await axios.get(
                    `${endPointGeneratedHistories}/${currentTopicContent}`,
                    {
                        headers,
                    }
                );
                if (response.data && response.status === 200) {
                    const parseResponse = JSON.parse(response.data.history.generatedContent);
                    if (response.data.history.associatedId === selectedProject) {
                        setGeneratedTopicContent(parseResponse);
                    } else {
                        setGeneratedTopicContent([]);
                    }
                }
            } catch (err) {
                // console.error(err);
            }
        };

        if (id && id !== 'new') {
            if (currentPrimaryKeywords) {
                fetchGeneratedHistoryPrimaryKeywords();
            }

            if (currentTopics) {
                fetchGeneratedHistoryTopics();
            }

            if (currentTopicImage) {
                fetchGeneratedHistoryTopicImage();
            }

            if (currentTopicContent) {
                fetchGeneratedHistoryTopicContent();
            }
        }
    }, [id, endPointPostContent, endPointGeneratedHistories, headers, postContent, generatedTopicImage, selectedProject, selectedPrimaryKeywords, currentPrimaryKeywords, currentTopics, currentTopicImage, currentTopicContent]);

    useEffect(() => {
        const fetchKeywords = async () => {
            setSelectedSaveKeywords([]);
            if (!selectedProject) return;

            try {
                const endPointKeywordManager = process.env.REACT_APP_OOM_SEO_API_KEYWORD_MANAGER;
                const response = await axios.get(
                    `${endPointKeywordManager}`,
                    {
                        params: {
                            project_id: selectedProject,
                            keyword_type: 'blog',
                        },
                        headers,
                    }
                );
                let keywords = [];
                if (response.data && response.status === 200) {
                    keywords = response.data.keywords;
                }
                setSaveKeywords(keywords);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    alert('Unauthorized: Please log in again.');
                }
            }
        };
        fetchKeywords();
    }, [headers, selectedProject]);

    useEffect(() => {
        const fetchAllKeywordsData = () => {
            const keywordData = {};

            // Combine saveKeywords and generatedPrimaryKeywords into one array
            //const allKeywords = [...(saveKeywords || []), ...(generatedPrimaryKeywords || [])];
            const allKeywords = [...(saveKeywords || [])];

            // Filter out keywords that have already been fetched
            const keywordsToFetch = allKeywords.filter(
                (keyword) => !keywordData[keyword.keyword]
            );

            // If there are no new keywords to fetch, return
            if (keywordsToFetch.length === 0) return;

            // Fire off API requests immediately without waiting for each one to finish
            keywordsToFetch.forEach((keyword) => {
                const endPointKeywordData = process.env.REACT_APP_OOM_SEO_API_KEYWORD_DATA;

                axios
                    .get(
                        `${endPointKeywordData}`,
                        {
                            params: {
                                keyword: keyword.keyword,
                                page_size: 1,
                            },
                            headers,
                        }
                    )
                    .then((response) => {
                        // Check if response is valid and contains the necessary data
                        if (response.data && response.data.status === 200) {
                            const keywordData = response.data.data;

                            // If no data is returned for the keyword, just skip it
                            if (!keywordData || keywordData.metrics.length === 0) {
                                //console.log(`No data available for keyword: ${keyword.keyword}`);
                                return "";
                            }

                            // Update state with the fetched keyword data
                            setKeywordData((prevData) => ({
                                ...prevData,
                                [keyword.keyword]: keywordData,
                            }));
                        } else {
                            console.warn(`Failed to fetch data for keyword: ${keyword.keyword}, Status: ${response.status}`);
                        }
                    })
                    .catch((error) => {
                        // Gracefully handle errors without throwing an unhandled exception
                        // if (error.response && error.response.status === 500) {
                        //     console.warn(`Server error occurred for keyword: ${keyword.keyword}. Status: 500`);
                        // } else {
                        //     console.error('Unexpected error occurred fetching keyword data', error);
                        // }
                    });
            });
        };

        // Only fetch if there are any keywords in either list
        if ((saveKeywords.length > 0)) {
        //if ((saveKeywords.length > 0) || (generatedPrimaryKeywords.length > 0)) {
            fetchAllKeywordsData();
        }
    }, [headers, saveKeywords]);

    const handleStep = (direction) => {
        const nextStep = direction === 'back' ? step - 1 : step + 1;
        setStep(nextStep);
        if (nextStep === 1) {
            setStepCurrentName("Primary Keywords");
        } else if (nextStep === 2) {
            setStepCurrentName("Primary Keywords");
        } else if (nextStep === 3) {
            setStepCurrentName("Topics");
        } else if (nextStep === 4) {
            setStepCurrentName("Blog Content");
        } else {
            setStepCurrentName("Select Keywords");
        }
    }

    const handleTopicImagePromptModal = (data) => {
        setTopicImagePromptModal(true); // Open the modal
    };

    const handleSearchVolumeModal = (data) => {
        setSelectedChartData(data); // Set the data for the chart
        setMonthlySearchVolumesModal(true); // Open the modal
    };

    const handleCloseModal = () => {
        setTopicImagePromptModal(false);
        setMonthlySearchVolumesModal(false);
        setSelectedChartData(null);
    };

    const handleSelectedSaveKeywords = (event, keyword) => {
        if (event.target.checked) {
            setSelectedSaveKeywords((prevSelected) => [...prevSelected, keyword]);
        } else {
            setSelectedSaveKeywords((prevSelected) =>
                prevSelected.filter((k) => k !== keyword)
            );
        }
    };

    const handleSelectedPrimaryKeywords = (event, keyword) => {
        if (event.target.checked) {
            setSelectedPrimaryKeywords((prevSelected) => [...prevSelected, keyword]);
        } else {
            setSelectedPrimaryKeywords((prevSelected) =>
                prevSelected.filter((k) => k !== keyword)
            );
        }
    };

    const handleSelectedTopic = async (topIndex, ideaIndex) => {
        const topic = generatedTopics[topIndex];
        const ideas = topic?.topic_ideas[ideaIndex];
        if (selectedTopic?.topic === ideas.title) {
            setSelectedTopic([]);
        } else if (topic && ideas) {
            setSelectedTopic({ primary_keywords: topic.selected_primary_keyword, topic: ideas.title, overview: ideas.overview });

            try {
                setLoading(true);
                setSavingPostContent(true);
                const response = await axios.put(
                    `${endPointPostContent}/${id}`,
                    {
                        post_name: ideas.title || '',
                        step: step || '',
                        project_id: selectedProject || '',
                        main_keywords: selectedSaveKeywords.length ? selectedSaveKeywords.join(',') : '',
                        additional_keywords: customKeywords.length ? customKeywords.join(',') : '',
                        primary_keywords: currentPrimaryKeywords || '',
                        selected_primary_keywords: selectedPrimaryKeywords.length ? selectedPrimaryKeywords.join(',') : '',
                        topics: currentTopics || '',
                        selected_topic: selectedTopic?.topic || '',
                        selected_topic_overview: selectedTopic?.overview || '',
                        topic_image: currentTopicImage || '',
                        topic_content: currentTopicContent || '',
                        language: selectedLanguage || '',
                        outline: inputTextOutline || '',
                        length: selectedContentLength || '',
                        audience: targetAudience.length ? targetAudience.join(',') : '',
                        exclude_terms: excludeTerms.length ? excludeTerms.join(',') : ''
                    },
                    { headers: headers }
                );
                if (response.status === 200) {
                    toast.success("Updated successfully");
                }
            } catch (error) {
                console.error('Error updating post content:', error);
            } finally {
                setLoading(false);
                setSavingPostContent(false);
                setPostContentName(ideas.title);
            }
        }
    };

    const handleProjectChange = (e) => {
        setSelectedProject(e.target.value);
    };

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    const handleContentLengthChange = (e) => {
        setSelectedContentLength(e.target.value);
    };

    const validatedLanguage = ['english', 'chinese', 'french', 'spanish'].includes(selectedLanguage) ? selectedLanguage : '';
    const validatedContentLength = ['300-600', '600-1000', '1000-1500', '1500-3000', '3000-6000'].includes(selectedContentLength) ? selectedContentLength : '';

    const handleGenerateTopicImage = async () => {
        setStep(4);
        setGeneratingTopicImage(true);
        setTopicImagePromptModal(false);
        try {
            const endPointGenerateImage = process.env.REACT_APP_OOM_SEO_API_GENERATE_IMAGE;
            const response = await axios.post(
                `${endPointGenerateImage}`,
                {
                    associated_id: selectedProject,
                    post_id: id,
                    prompt: topicImagePrompt,
                    count: 1
                },
                { headers }
            );
            if (response?.data && response?.data.status === 200) {
                setGeneratingTopicImage(false);
                setGeneratedTopicImage(response.data.generated_content);
                setCurrentTopicImage(response.data.generated_image_id);
                if (response.data.generated_image_id) {
                    try {
                        if (response.data.generated_content.message !== "No image was generated. try another prompt.") {
                            await axios.put(
                                `${endPointPostContent}/${id}`,
                                {
                                    associated_id: selectedProject,
                                    post_id: id,
                                    topic_image: response.data.generated_image_id,
                                    main_keywords: selectedSaveKeywords.join(', '),
                                    primary_keywords: currentPrimaryKeywords,
                                    current_step: step
                                },
                                { headers: headers }
                            );
                            toast.success(response.data.generated_content.message);
                        } else {
                            toast.error(response.data.generated_content.message);
                        }
                    } catch (error) {
                        toast.error("Something went wrong!");
                        console.error('Error updating post content:', error);
                    }
                }
            }
        } catch (error) {
            console.error('Error generating image:', error);
            toast.error(error.message || 'An unexpected error occurred.');
        }
    };


    const handleGeneratePrimaryKeywords = async () => {
        const keywords = [...selectedSaveKeywords, ...customKeywords].join(', ');
        const keywordsTotal = keywords.split(',').map(item => item.trim());

        if (keywords.length <= 0) {
            toast.error("Please select or enter atleast 1 keyword");
            return;
        }

        if (keywordsTotal.length > 5) {
            toast.info("Allowed Maximum 5 Keywords only including additional/custom keywords");
            return;
        }

        setLoading(true);
        setGeneratingPrimaryKeywords(true);
        try {
            const endPointGeneratePrimaryKeywords = process.env.REACT_APP_OOM_SEO_API_GENERATE_PRIMARY_KEYWORDS;
            const response = await axios.post(
                `${endPointGeneratePrimaryKeywords}`,
                { keywords: keywords, associated_id: selectedProject },
                { headers: headers }
            );
            const parseResponse = JSON.parse(response.data.generated_content);
            setGeneratedPrimaryKeywords(parseResponse);

            if (id && id === 'new') {
                const postResponse = await axios.post(
                    `${endPointPostContent}`,
                    {
                        project_id: selectedProject,
                        post_id: id,
                        main_keywords: selectedSaveKeywords.join(', '),
                        additional_keywords: customKeywords.join(', '),
                        primary_keywords: response.data.id,
                    },
                    { headers }
                );
                if (postResponse.data && postResponse.status === 200) {
                    navigate(`/ai-generation/${postResponse.data.id}`);
                }
            } else {
                if (response.data.id !== selectedPrimaryKeywords) {
                    try {
                        const updateResponse = await axios.put(
                            `${endPointPostContent}/${id}`,
                            {
                                post_name: postContentName,
                                project_id: selectedProject,
                                post_id: id,
                                main_keywords: selectedSaveKeywords.join(','),
                                additional_keywords: customKeywords.join(','),
                                primary_keywords: response.data.id,
                            },
                            { headers: headers }
                        );

                        if (updateResponse.status === 200) {
                            toast.success("Primary Keywords Generated successfully");
                        }
                    } catch (error) {
                        toast.error("Something went wrong!");
                        console.error('Error updating post content:', error);
                    }
                }
            }
            setStep(2);
            setStepCurrentName("Primary Keywords");
        } catch (error) {
            console.error('Error generating primary keywords:', error);
        } finally {
            setLoading(false);
            setGeneratingPrimaryKeywords(false);
        }
    };

    const handleGenerateTopic = async (e) => {
        e.preventDefault();
        const keywords = [...selectedSaveKeywords, ...customKeywords].join(', ');
        if (keywords.length <= 0) {
            toast.error("Please select or enter atleast 1 keyword");
            return;
        }

        if (selectedPrimaryKeywords.length <= 0 || selectedPrimaryKeywords.length > 3) {
            toast.error("Please select atleast 3 Primary keywords");
            return;
        }

        setLoading(true);
        setGeneratingTopic(true);
        try {
            const endPointGenerateTopic = process.env.REACT_APP_OOM_SEO_API_GENERATE_TOPIC;
            const response = await axios.post(
                `${endPointGenerateTopic}`,
                { primary_keywords: selectedPrimaryKeywords, main_keywords: selectedSaveKeywords, associated_id: selectedProject },
                { headers: headers }
            );
            const parseResponse = JSON.parse(response.data.generated_content);
            setGeneratedTopics(parseResponse);

            if (response.data && response.status === 200) {
                try {
                    const updateResponse = await axios.put(
                        `${endPointPostContent}/${id}`,
                        {
                            post_name: postContentName,
                            project_id: selectedProject,
                            post_id: id,
                            main_keywords: selectedSaveKeywords.join(','),
                            additional_keywords: customKeywords.join(','),
                            primary_keywords: currentPrimaryKeywords,
                            selected_primary_keywords: selectedPrimaryKeywords.join(','),
                            topics: response.data.id
                        },
                        { headers: headers }
                    );

                    if (updateResponse.status === 200) {
                        toast.success("Topics Generated successfully");
                    }
                } catch (error) {
                    toast.error("Something went wrong!");
                    console.error('Error updating post content:', error);
                }
            }
            setStep(3);
            setStepCurrentName("Topics");
        } catch (error) {
            console.error('Error generating topic:', error);
        } finally {
            setLoading(false);
            setGeneratingTopic(false);
        }
    };

    const handleGenerateTopicContent = async (e) => {
        e.preventDefault();
        if (!selectedTopic || selectedTopic.topic === undefined) {
            toast.error("Please select Topic to generate content");
            return;
        }

        if (targetAudience.length <= 0) {
            toast.error("Please enter at least one audience");
            return;
        }

        setLoading(true);
        setGeneratingTopicContent(true);

        const endPointGenerateTopicContent = process.env.REACT_APP_OOM_SEO_API_GENERATE_TOPIC_CONTENT;

        try {
            // Generate topic content
            const { data, status } = await axios.post(
                endPointGenerateTopicContent,
                {
                    topic: selectedTopic.topic,
                    topic_overview: selectedTopic.overview,
                    primary_keywords: selectedTopic.primary_keywords,
                    associated_id: selectedProject,
                    language: selectedLanguage,
                    outline: inputTextOutline,
                    words: selectedContentLength,
                    audience: targetAudience.join(', ')
                },
                { headers }
            );

            if (status !== 200 || !data) {
                toast.error("Failed to generate content");
                return;
            }

            const generatedContent = JSON.parse(data.generated_content);
        
            const updatePayload = {
                step: step,
                post_name: postContentName,
                project_id: selectedProject,
                main_keywords: selectedSaveKeywords.join(','),
                additional_keywords: customKeywords.join(','),
                primary_keywords: currentPrimaryKeywords,
                selected_primary_keywords: selectedPrimaryKeywords.join(','),
                topics: currentTopics,
                topic_image: data.generated_image_id,
                selected_topic: selectedTopic.topic,
                selected_topic_overview: selectedTopic.overview,
                topic_content: data.id,
                language: selectedLanguage,
                outline: inputTextOutline,
                length: selectedContentLength,
                audience: inputTargetAudience,
                exclude_terms: inputExcludeTerms
            };

            const updateResponse = await axios.put(`${endPointPostContent}/${id}`, updatePayload, { headers });

            setGeneratedTopicContent(generatedContent);
            setCurrentTopicContent(data.id);
            setCurrentTopicImage(data.generated_image_id);

            if (updateResponse.status === 200) {
                toast.success("Content Generated successfully");
                setStep(4);
                setStepCurrentName("Blog Content");
            } else {
                throw new Error("Failed to update content");
            }
            
        } catch (error) {
            console.error('Error during content generation:', error);
            toast.error("Something went wrong!");
        } finally {
            setLoading(false);
            setGeneratingTopicContent(false);
        }
    };

    const handleSavePostContent = async (e) => {
        e.preventDefault();
        if (!selectedSaveKeywords.length) {
            toast.error("Please select atleast one keyword");
            return;
        }

        try {
            setLoading(true);
            setSavingPostContent(true);
            const response = await axios.put(
                `${endPointPostContent}/${id}`,
                {
                    post_name: postContentName || '',
                    step: step,
                    project_id: selectedProject || '',
                    main_keywords: selectedSaveKeywords.length ? selectedSaveKeywords.join(',') : '',
                    additional_keywords: customKeywords.length ? customKeywords.join(',') : '',
                    primary_keywords: currentPrimaryKeywords || '',
                    selected_primary_keywords: selectedPrimaryKeywords.length ? selectedPrimaryKeywords.join(',') : '',
                    topics: currentTopics || '',
                    selected_topic: selectedTopic?.topic || '',
                    selected_topic_overview: selectedTopic?.overview || '',
                    topic_image: currentTopicImage || '',
                    topic_content: currentTopicContent || '',
                    language: selectedLanguage || '',
                    outline: inputTextOutline || '',
                    length: selectedContentLength || '',
                    audience: targetAudience.length ? targetAudience.join(',') : '',
                    exclude_terms: excludeTerms.length ? excludeTerms.join(',') : ''
                },
                { headers: headers }
            );

            if (response.status === 200) {
                toast.success("Updated successfully");
            }
        } catch (error) {
            console.error('Error updating post content:', error);
        } finally {
            setLoading(false);
            setSavingPostContent(false);
        }
    };

    const handleInputChangeKeyword = (e) => {
        setInputKeyword(e.target.value);
    };

    const handleInputChangeAudience = (e) => {
        setInputTargetAudience(e.target.value);
    };

    const handleInputChangeExcludeTerms = (e) => {
        setInputExcludeTerms(e.target.value);
    };

    const handleKeyPressKeyword = (e) => {
        if (e.key === "Enter" && inputKeyword.trim()) {
            e.preventDefault();
            if (!customKeywords.includes(inputKeyword.trim())) {
                setCustomKeywords([...customKeywords, inputKeyword.trim()]);
            }
            setInputKeyword("");
        }
    };

    const handleKeyPressAudience = (e) => {
        if (e.key === "Enter" && inputTargetAudience.trim()) {
            e.preventDefault();
            if (!targetAudience.includes(inputTargetAudience.trim())) {
                setTargetAudience([...targetAudience, inputTargetAudience.trim()]);
            }
            setInputTargetAudience("");
        }
    };

    const handleKeyPressExcludeTerms = (e) => {
        if (e.key === "Enter" && inputExcludeTerms.trim()) {
            e.preventDefault();
            if (!excludeTerms.includes(inputExcludeTerms.trim())) {
                setExcludeTerms([...excludeTerms, inputExcludeTerms.trim()]);
            }
            setInputExcludeTerms("");
        }
    };

    const handleGenerateNew = () => {
        navigate('/ai-generation/new/');
        setStep(1);
        setStepCurrentName("Select Keywords");
        setPostContentName("");
        setSelectedProject("");
        setSaveKeywords([]);
        setCustomKeywords([]);
        setGeneratedPrimaryKeywords([]);
        setGeneratedTopics("");
        setGeneratedTopicContent("");
    };

    const handleInputPostName = (event) => {
        setPostContentName(event.target.value);
    };

    const handleInputTextOutline = (event) => {
        setInputTextOutline(event.target.value);
    };

    const removeCustomKeyword = (indexToRemove) => {
        setCustomKeywords(customKeywords.filter((_, index) => index !== indexToRemove));
    };

    const removeTargetAudience = (indexToRemove) => {
        setTargetAudience(targetAudience.filter((_, index) => index !== indexToRemove));
    };

    const removeExcludeTerms = (indexToRemove) => {
        setExcludeTerms(excludeTerms.filter((_, index) => index !== indexToRemove));
    };

    return (
        <React.Fragment>
             <div className="oom-page-header">
                <div className="oom-page-attributes breadcrumb ai-generation">
                    <p className="oom-page-attributes_breadcrumb">Content Generation  / <span className="highlight">{id && id === 'new' ? 'Generate New' : stepCurrentName}</span></p>
                    <p className="oom-page-attributes_title">
                        Content Generation <span className="highlight">/ {postContentName ? postContentName : "Generate New"}</span>
                    </p>
                    <p className="oom-page-attributes__arrow">
                        {step > 1 && (
                            <span className="text" onClick={() => handleStep('back')}> <ChevronLeftIcon sx={{ fontSize: 14 }} /> Back </span>
                        )}
                    </p>
                </div>
            </div>
            
            <div className="oom-page-content">
                <div className="oom-form_list_container ai-generation">
                    {id && id !== 'new' && (
                        <div className='oom-field-container__row flex-direction-row justify'>
                            <div className='oom-field-container__row flex-direction-row'>
                                <div className="oom-field-container__row_heading">
                                    <span className="container__heading">Content Generation Name:</span>
                                </div>

                                <div className='oom-field oom-text-field custom-width'>
                                    <TextField
                                        // label="Enter Your Blog Name"
                                        variant="outlined"
                                        placeholder="e.g. AI and Beyond in Content Generation"
                                        disabled={loading}
                                        value={postContentName}
                                        onChange={handleInputPostName}
                                        fullWidth
                                    />
                                </div>
                            </div>
                            {step !== 4 && (
                                <div className="generated-content_buttons">
                                    <button onClick={handleSavePostContent} title="Save" className="oom-button" disabled={savingPostContent || loading}>
                                        <SaveIcon sx={{ fontSize: 18 }} /> SAVE
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    <div id="step-1" className={`oom-field-container__row ${step !== 1 ? 'hide' : ''}`}>
                        {id && id === "new" && (
                            <div className='oom-field-container__row flex-direction-row'>
                                <div className='oom-field-container__row_heading'>
                                    <span className='container__heading'>Select keywords from:</span>
                                </div>
                                <Select
                                    labelId="project-select-label"
                                    className='oom-field oom-field-select material'
                                    value={selectedProject}
                                    onChange={handleProjectChange}
                                    fullWidth
                                    disabled={loading}
                                >
                                    <MenuItem disabled value="">
                                        Select Project
                                    </MenuItem>

                                    {projects.length > 0 ? (
                                        projects.map((project) => (
                                            <MenuItem key={project._id} value={project._id}>
                                                {project.projectName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No projects found</MenuItem>
                                    )}
                                </Select>
                            </div>
                        )}

                        <div className='oom-field-container__row'>
                            <div className="oom-page-results__details saved-keyword-ideas">
                                <div className="oom-page-results__details_results">
                                    <div className="oom-page-results__details_results_sub_heading">
                                        <span>Keywords Ideas</span>
                                    </div>

                                    <table className="oom-page-results__details_results_table">
                                        <thead className="oom-page-results__details_results_table_heading">
                                            <tr>
                                                <th className="oom-page-results__details_results_table_heading_item oom-column-30"><span>Keywords</span></th>
                                                <th className="oom-page-results__details_results_table_heading_item oom-column-10"><span>Search Volume </span></th>
                                                <th className="oom-page-results__details_results_table_heading_item oom-column-10"><span>History </span></th>
                                                <th className="oom-page-results__details_results_table_heading_item oom-column-15"><span>CPC (low range)</span></th>
                                                <th className="oom-page-results__details_results_table_heading_item oom-column-15"><span>CPC (high range)</span></th>
                                                <th className="oom-page-results__details_results_table_heading_item oom-column-20"><span>Competition</span></th>
                                            </tr>
                                        </thead>
                                        <tbody className="oom-page-results__details_results_items">
                                            {(Array.isArray(saveKeywords) && saveKeywords.length > 0) ? (
                                                saveKeywords.map((keyword) => {
                                                    const monthlySearchVolumes = keywordData[keyword.keyword]?.metrics?.monthly_search_volumes || [];
                                                    return (
                                                        <tr key={keyword._id} className="oom-page-results__details_results_table_item">
                                                            <td className="column-item oom-column-30">
                                                                <div className="keyword-input">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedSaveKeywords.includes(keyword.keyword)}
                                                                        disabled={loading}
                                                                        onChange={(e) => handleSelectedSaveKeywords(e, keyword.keyword)}
                                                                    />
                                                                    <div className='keyword-container'>
                                                                        <span className='keyword'>{keyword.keyword}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="column-item oom-column-10">
                                                                <div className='search-volume'>
                                                                    <span>
                                                                        {formatNumber(monthlySearchVolumes.slice(-1)[0]?.monthly_searches) || <Loader />}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="column-item oom-column-10">
                                                                <div className='history'>
                                                                    {monthlySearchVolumes.length === 0 ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <MonthlySearchChart
                                                                            changeDisplay={false}
                                                                            monthlySearchVolumes={monthlySearchVolumes}
                                                                            onClick={() => handleSearchVolumeModal(monthlySearchVolumes)}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="column-item oom-column-15">
                                                                <div className='cpc-low'>
                                                                    {keywordData[keyword.keyword]?.metrics?.low_top_of_page_bid_micros ? (
                                                                        `SGD ${(keywordData[keyword.keyword]?.metrics?.low_top_of_page_bid_micros / 1000000).toFixed(2)}`
                                                                    ) : (
                                                                        "N/A"
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="column-item oom-column-15">
                                                                <div className='cpc-high'>
                                                                    {keywordData[keyword.keyword]?.metrics?.low_top_of_page_bid_micros ? (
                                                                        `SGD ${(keywordData[keyword.keyword]?.metrics?.high_top_of_page_bid_micros / 1000000).toFixed(2)}`
                                                                    ) : (
                                                                        "N/A"
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="column-item oom-column-20">
                                                                <div className='competition'>
                                                                    <span>
                                                                        {keywordData[keyword.keyword]?.metrics?.competition ? (
                                                                            keywordData[keyword.keyword]?.metrics?.competition
                                                                        ) : (
                                                                            <Loader />
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (

                                                <tr>
                                                    <td colSpan={6} className="no-found">
                                                        {selectedProject ? (
                                                            <p>
                                                                No saved keywords found. Please add keywords using the{' '}
                                                                <a
                                                                    href="/research/keyword-suggestion"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="link-underline"
                                                                >
                                                                    Keyword Suggestion
                                                                </a>{' '}
                                                                to proceed.
                                                            </p>
                                                        ) : (
                                                            <p>Select project</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='oom-field-container__row'>
                            <div className='oom-field-container__row_heading'>
                                <span className='container__heading'>
                                    Additional keywords
                                    <Tooltip
                                        title={
                                            <>
                                                <strong>Tips</strong>
                                                <p>Try not to be too specific or general. For example, "meal delivery" is better than "meals" for a food delivery business.</p>
                                            </>
                                        }
                                        className="tooltip-container"
                                        placement="top-start"
                                        followCursor>
                                        <InfoIcon sx={{ fontSize: 14 }} className="tooltip-icon" />
                                    </Tooltip>
                                </span>
                            </div>

                            <div className="custom-inputs">
                                {customKeywords.map((customKeyword, index) => (
                                    <div className="custom-keywords" key={index}>
                                        {customKeyword}
                                        <span className="remove-item" onClick={() => removeCustomKeyword(index)}>
                                            &times;
                                        </span>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    value={inputKeyword}
                                    disabled={loading}
                                    onChange={handleInputChangeKeyword}
                                    onKeyPress={handleKeyPressKeyword}
                                    placeholder="Try &quot;meal delivery&quot; or &quot;leather boots&quot;"
                                />
                            </div>
                        </div>

                        {saveKeywords.length > 0 && (
                            <>
                                {loading && (
                                    <Loader />
                                )}

                                <div className='oom-field-container__row action'>
                                    {!loading && (
                                        <>
                                            <button onClick={handleGeneratePrimaryKeywords} title="Generate Primary Keywords" className="oom-button oom-btn-loader action" disabled={loading}>
                                                {generatingPrimaryKeywords
                                                    ?
                                                    <>
                                                        <span className='text loading'>Loading</span>
                                                        <Loader />
                                                    </>

                                                    : <span className='text'>Generate Primary Keywords</span>
                                                }
                                            </button>

                                            {generatedPrimaryKeywords.length > 0 && (
                                                <button onClick={() => handleStep()} title="View Step" className="oom-button oom-btn-loader action" disabled={loading}>
                                                    <span className='text'>View Primary Keywords</span>
                                                </button>
                                            )}

                                        </>
                                    )}
                                </div>
                            </>
                        )}

                    </div>

                    <div id="step-2" className={`oom-field-container__row ${step !== 2 ? 'hide' : ''}`}>
                        {generatedPrimaryKeywords.length > 0 && !generatingPrimaryKeywords && (
                            <div className='oom-field-container__row'>
                                <div className='oom-field-container__row_heading'>
                                    <span className='container__heading'>Generate Topic</span>
                                    <span className='container__sub_heading'>Choose Keywords to generate topic
                                        <Tooltip
                                            title={
                                                <>
                                                    <strong>Tips</strong>
                                                    <p>Select primary keywords to proceed (maximum 3).</p>
                                                </>
                                            }
                                            className="tooltip-container"
                                            placement="top-start"
                                            followCursor>
                                            <InfoIcon sx={{ fontSize: 14 }} className="tooltip-icon" />
                                        </Tooltip>
                                    </span>
                                </div>

                                <div className="oom-page-results__details generated-primary-keywords">
                                    <div className="oom-page-results__details_results">

                                        <table className="oom-page-results__details_results_table">
                                            <thead className="oom-page-results__details_results_table_heading">
                                                <tr>
                                                    <th className="oom-page-results__details_results_table_heading_item oom-column-60"><span>Generated Primary Keywords</span></th>
                                                    {/* <th className="oom-page-results__details_results_table_heading_item oom-column-20"><span>Total Volume</span></th> */}
                                                    {/* <th className="oom-page-results__details_results_table_heading_item oom-column-20"><span>History</span></th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="oom-page-results__details_results_items">
                                                {(Array.isArray(generatedPrimaryKeywords) ? generatedPrimaryKeywords : []).map((keyword, index) => {
                                                    const monthlySearchVolumes = keywordData[keyword.keyword]?.metrics?.monthly_search_volumes || [];
                                                    return (
                                                        <tr key={index} className="oom-page-results__details_results_table_item">
                                                            <td className="column-item oom-column-60">
                                                                <div className="keyword-input">
                                                                    <input
                                                                        type="checkbox"
                                                                        disabled={loading}
                                                                        checked={selectedPrimaryKeywords.includes(keyword.keyword)}
                                                                        onChange={(e) => handleSelectedPrimaryKeywords(e, keyword.keyword)}
                                                                    />
                                                                    <div className="keyword-container">
                                                                        <span className='keyword'>{keyword.keyword}</span>
                                                                        <span className='description'><InfoIcon sx={{ fontSize: 14 }} /> {keyword.explanation}</span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            {/* <td className="column-item oom-column-20">
                                                                <div className='search-volume'>
                                                                    <span>
                                                                        {formatNumber(monthlySearchVolumes.slice(-1)[0]?.monthly_searches) || "NA"}
                                                                    </span>
                                                                </div>
                                                            </td> */}

                                                            {/* <td className="column-item oom-column-20">
                                                                <div className='history'>
                                                                    {monthlySearchVolumes.length > 0 && (
                                                                        <MonthlySearchChart
                                                                            changeDisplay={false}
                                                                            monthlySearchVolumes={monthlySearchVolumes}
                                                                            onClick={() => handleSearchVolumeModal(monthlySearchVolumes)}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td> */}

                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {generatedPrimaryKeywords.length > 0 && (
                            <>
                                {loading && (
                                    <Loader />
                                )}

                                <div className='oom-field-container__row action'>
                                    {!loading && (
                                        <>
                                            <button onClick={handleGenerateTopic} title="Generate Topic" className="oom-button oom-btn-loader action" disabled={loading}>
                                                {generatingTopic
                                                    ?
                                                    <>
                                                        <span className='text loading'>Loading</span>
                                                        <Loader />
                                                    </>

                                                    : <span className='text'>Generate Topics</span>
                                                }
                                            </button>

                                            {generatedTopics.length > 0 && (
                                                <button onClick={() => handleStep()} title="View Step" className="oom-button oom-btn-loader action" disabled={loading}>
                                                    <span className='text'>View Topics</span>
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                    <div id="step-3" className={`oom-field-container__row ${step !== 3 ? 'hide' : ''}`}>

                        {generatedPrimaryKeywords.length > 0 && (
                            <>
                                {generatedTopics.length > 0 && !generatingTopic && (
                                    <>
                                        <div className='oom-field-container__row'>
                                            <div className="oom-page-results__details generated-topics">
                                                <div className="oom-page-results__details_results">
                                                    <div className="oom-page-results__details_results_sub_heading">
                                                        <span>Generated Topics</span>
                                                    </div>

                                                    <table className="oom-page-results__details_results_table">
                                                        <thead className="oom-page-results__details_results_table_heading">
                                                            <tr>
                                                                <th className="oom-page-results__details_results_table_heading_item oom-column-15"><span>Selected Primary Keywords</span></th>
                                                                <th className="oom-page-results__details_results_table_heading_item oom-column-15"><span>Article Topic</span></th>
                                                                <th className="oom-page-results__details_results_table_heading_item oom-column-20"><span>Article Overview</span></th>
                                                                <th className="oom-page-results__details_results_table_heading_item oom-column-15"><span>Semantic Keywords</span></th>
                                                                <th className="oom-page-results__details_results_table_heading_item oom-column-20"><span>How to Interlink <br />Primary Keyword</span></th>
                                                                <th className="oom-page-results__details_results_table_heading_item oom-column-15"><span>Keywords to Interlinked</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="oom-page-results__details_results_items">
                                                            {(selectedPrimaryKeywords.length && Array.isArray(generatedTopics) ? generatedTopics : []).map((topic, topicIndex) => (
                                                                <React.Fragment key={topicIndex}>
                                                                    {(Array.isArray(topic.topic_ideas) ? topic.topic_ideas : []).map((idea, ideaIndex) => (
                                                                        <tr key={`${topicIndex}-${ideaIndex}`} className="oom-page-results__details_results_table_item">
                                                                            <td className="column-item oom-column-15">
                                                                                <div className="keyword-input">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        disabled={loading}
                                                                                        checked={selectedTopic?.topic === idea.title}
                                                                                        onChange={(e) => handleSelectedTopic(topicIndex, ideaIndex)}
                                                                                    />
                                                                                    <div className='result-text keyword-container'>
                                                                                        <span className='keyword'>{capitalizeString(topic.selected_primary_keyword)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td className="column-item oom-column-15">
                                                                                <div className='result-text generated-topic-idea'>
                                                                                    <span>{idea.title}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td className="column-item oom-column-20">
                                                                                <div className='result-text generated-topic-overview'>
                                                                                    <span>{idea.overview}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td className="column-item oom-column-15">
                                                                                <div className='result-text generated-semanrtic-keywords'>
                                                                                    <span>{idea.semantic_keywords.join(', ')}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td className="column-item oom-column-20">
                                                                                <div className='result-text generated-how-interlink-primary-keywords'>
                                                                                    <span>{idea.how_to_interlink_primary_keywords}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td className="column-item oom-column-15">
                                                                                <div className='result-text generated-semanrtic-keywords'>
                                                                                    <span>{idea.keywords_to_interlink.join(', ')}</span>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='oom-field-container__row'>
                                            <div className='oom-field-container__row_heading'>
                                                <span className='container__heading'>Content Generation Prompt</span>
                                                <span className='container__sub_heading'>Choose 1 Topic to generate Content
                                                    <Tooltip
                                                        title={
                                                            <>
                                                                <strong>Tips</strong>
                                                                <p>Select a topic to proceed with content generation.</p>
                                                            </>
                                                        }
                                                        className="tooltip-container"
                                                        placement="top-start"
                                                        followCursor>
                                                        <InfoIcon sx={{ fontSize: 14 }} className="tooltip-icon" />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        </div>

                                        <div className='oom-field-container__row'>
                                            <div className="oom-field-container__row_heading">
                                                <span className="container__heading">Specific Language</span>
                                            </div>

                                            <div className='oom-field'>
                                                <Select
                                                    labelId="language-select-label"
                                                    value={validatedLanguage}
                                                    onChange={handleLanguageChange}
                                                    disabled={loading}
                                                    label="Language"
                                                >
                                                    <MenuItem value="english">English</MenuItem>
                                                    <MenuItem value="chinese">Chinese</MenuItem>
                                                    <MenuItem value="french">French</MenuItem>
                                                    <MenuItem value="spanish">Spanish</MenuItem>
                                                </Select>
                                            </div>
                                        </div>

                                        <div className='oom-field-container__row'>
                                            <div className="oom-field-container__row_heading">
                                                <span className="container__heading">Outline</span>
                                            </div>

                                            <div className='oom-field oom-text-field'>
                                                <TextField
                                                    label="Paste your pre-prepared outline here."
                                                    variant="outlined"
                                                    placeholder='Default value: generate an outline before writing, with at least 15 headings/subheadings in a mix of H1–H4 levels.'
                                                    value={inputTextOutline}
                                                    onChange={handleInputTextOutline}
                                                    disabled={loading}
                                                    rows={5}
                                                    multiline
                                                    fullWidth
                                                />
                                            </div>
                                        </div>

                                        <div className='oom-field-container__row'>
                                            <div className="oom-field-container__row_heading">
                                                <span className="container__heading">Content Length</span>
                                            </div>

                                            <div className='oom-field'>
                                                <Select
                                                    labelId="content-length-select-label"
                                                    value={validatedContentLength}
                                                    onChange={handleContentLengthChange}
                                                    disabled={loading}
                                                    label="Content Length"
                                                >
                                                    <MenuItem value="300-600">300 - 600 Words</MenuItem>
                                                    <MenuItem value="600-1000">600 - 1000 Words</MenuItem>
                                                    <MenuItem value="1000-1500">1000 - 1500 Words</MenuItem>
                                                    <MenuItem value="1500-3000">1500 - 3000 Words</MenuItem>
                                                    <MenuItem value="3000-6000">3000 - 6000 Words</MenuItem>
                                                </Select>
                                            </div>
                                        </div>

                                        <div className='oom-field-container__row'>
                                            <div className='oom-field-container__row_heading'>
                                                <span className="container__heading">Target Audience</span>
                                            </div>

                                            <div className="custom-inputs">
                                                {targetAudience.map((audience, index) => (
                                                    <div className="custom-keywords" key={index}>
                                                        {audience}
                                                        <span className="remove-item" onClick={() => removeTargetAudience(index)}>
                                                            &times;
                                                        </span>
                                                    </div>
                                                ))}

                                                <input
                                                    type="text"
                                                    value={inputTargetAudience}
                                                    disabled={loading}
                                                    onChange={handleInputChangeAudience}
                                                    onKeyPress={handleKeyPressAudience}
                                                    placeholder="e.g. beginners, professionals, entrepreneurs or Business Owners"
                                                />
                                            </div>
                                        </div>

                                        <div className='oom-field-container__row'>
                                            <div className='oom-field-container__row_heading'>
                                                <span className="container__heading">Exclude Terms</span>
                                            </div>

                                            <div className="custom-inputs">
                                                {excludeTerms.map((excludeTerm, index) => (
                                                    <div className="custom-keywords" key={index}>
                                                        {excludeTerm}
                                                        <span className="remove-item" onClick={() => removeExcludeTerms(index)}>
                                                            &times;
                                                        </span>
                                                    </div>
                                                ))}

                                                <input
                                                    type="text"
                                                    value={inputExcludeTerms}
                                                    disabled={loading}
                                                    onChange={handleInputChangeExcludeTerms}
                                                    onKeyPress={handleKeyPressExcludeTerms}
                                                    placeholder="e.g. guarantee"
                                                />
                                            </div>
                                        </div>

                                        {loading && (
                                            <Loader />
                                        )}

                                        <div className="oom-field-container__row action">
                                            {!loading && (
                                                <button onClick={handleGenerateTopicContent} title="Generate Content" className='oom-button oom-btn-loader action' disabled={generatingTopicContent}>
                                                    {generatingTopicContent
                                                        ?
                                                        <>
                                                            <span className='text loading'>Loading</span>
                                                            <Loader />
                                                        </>
                                                        : <span className='text'>Generate Content</span>
                                                    }
                                                </button>
                                            )}

                                            {generatedTopicContent.title_tag && !generatingTopicContent && (
                                                <button onClick={() => handleStep()} title="View Content" className="oom-button oom-btn-loader action" disabled={loading}>
                                                    <span className='text'>View Content</span>
                                                </button>
                                            )}
                                        </div>
                                    </>
                                )}

                            </>
                        )}

                    </div>

                    <div id="step-4" className={`oom-field-container__row_flex ${step !== 4 ? 'hide' : ''}`}>
                        <div className="oom-field-container__content">
                            {generatedTopicContent.title_tag && !generatingTopicContent && (
                                <>
                                    <div className="generated-content_container">
                                        <span className="section-label">TOPIC</span>
                                        <h4 className="topic">{selectedTopic.topic}</h4>
                                    </div>

                                    <div id="overview" className="generated-content_container">
                                        <span className="section-label">OVERVIEW</span>
                                        <p className="overview">{selectedTopic.overview}</p>
                                    </div>

                                    <div id="meta-data" className="generated-content_container">
                                        <span className="section-label">META DATA</span>
                                        <div className="generated-content_meta">
                                            <div className="meta-data title">
                                                <span className="label">Title</span>
                                                <span className="value">{generatedTopicContent.title_tag}</span>
                                            </div>

                                            <div className="meta-data description">
                                                <span className="label">Description</span>
                                                <span className="value">{generatedTopicContent.meta_description}</span>
                                            </div>

                                            <div className='meta-data slug'>
                                                <span className='label'>Slug</span>
                                                <span className='value'>{generatedTopicContent.slug}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="blog-content" className="generated-content_container">
                                        <span className="section-label">BLOG CONTENT</span>
                                        <div className="generated-content">
                                
                                            <h1>
                                                {generatedTopicContent.h1}
                                                <Tooltip
                                                    title={
                                                        <>
                                                            <strong>H1</strong>
                                                            <p>characters length - {generatedTopicContent.h1.length}</p>
                                                        </>
                                                    }
                                                    className="tooltip-container"
                                                    placement="top-start"
                                                    followCursor
                                                    >
                                                    <InfoIcon sx={{ fontSize: 14 }} className="tooltip-icon" />
                                                </Tooltip>
                                            </h1>

                                            {renderContent(generatedTopicContent.content)}
                                            
                                        </div>
                                    </div>

                                    <div id="recommended-images" className="generated-content_container">
                                        <span className="section-label">RECOMMENDED IMAGES</span>
                                        <div className="generated-content-image">
                                            {generatingTopicImage ? (
                                                <Loader />
                                            ) : (
                                                (generatedTopicImage?.images || []).map((image, index) => (
                                                    <figure key={index}>
                                                        <img
                                                            src={`${server_uri}/${image.file_url}`}
                                                            alt={`${generatedTopicImage.prompt} - ${index}`}
                                                            key={`img-${index}`}
                                                        />
                                                        <figcaption key={`caption-${index}`}> 
                                                        <>Alt text: {generatedTopicImage.prompt}</>
                                                        </figcaption>
                                                    </figure>
                                                ))
                                            )}
                                        </div>

                                        <div className="oom-field-container__row action center">
                                            <div className='ai-suggest button'>
                                                <button onClick={handleTopicImagePromptModal} title="Generate Topic Image" className="icon-svg gemini-arrow" disabled={loading}>
                                                    <AiGenerateIcon />
                                                    {generatingTopicImage ? (
                                                        <span className="text loading">Loading</span>
                                                    ) : generatedTopicImage ? (
                                                        <span className="text">Generate New Image</span>
                                                    ) : (
                                                        <span className="text">Generate Image</span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="conclusion" className="generated-content_container">
                                        <span className="section-label">CONCLUSION</span>
                                        <div className="generated-content">
                                            <h2>{generatedTopicContent.conclusion.h2}
                                                <Tooltip
                                                    title={
                                                        <>
                                                            <strong>H2</strong>
                                                            <p>characters length - {generatedTopicContent.h1.length}</p>
                                                        </>
                                                    }
                                                    className="tooltip-container"
                                                    placement="top-start"
                                                    followCursor>
                                                    <InfoIcon sx={{ fontSize: 14 }} className="tooltip-icon" />
                                                </Tooltip>
                                            </h2>
                                            <p>{generatedTopicContent.conclusion.p}</p>
                                        </div>
                                    </div>

                                    <div id="references" className="generated-content_container">
                                        <span className="section-label">REFERENCES</span>
                                        <ul className="references">
                                            {generatedTopicContent.reference_links.map((link, index) => (
                                                <li key={index}>
                                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                                        {link}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                                
                            )}

                            <div className="oom-field-container__row action">
                                <button onClick={() => handleStep('back')} title="View Step" className="oom-button oom-btn-loader action" disabled={loading}>
                                    <span className='text'>Go back</span>
                                </button>
                            </div>
                        </div>

                        <div className="oom-field-container__navigation sticky-navigation">
                            <ul className="generated-content_navigation">
                                <li><a className="nav-link" href="#overview">OVERVIEW</a></li>
                                <li><a className="nav-link" href="#meta-data">META DATA</a></li>
                                <li><a className="nav-link" href="#blog-content">BLOG CONTENT</a></li>
                                <li><a className="nav-link" href="#recommended-images">RECOMMENDED IMAGES</a></li>
                                <li><a className="nav-link" href="#conclusion">CONCLUSION</a></li>
                                <li><a className="nav-link" href="#references">REFERENCES</a></li>
                            </ul>
                            <div className="generated-content_buttons">
                                <button onClick={handleSavePostContent} title="Save Post Content" className="oom-button" disabled={savingPostContent || loading}>
                                    <SaveIcon sx={{ fontSize: 18 }} /> SAVE CONTENT
                                </button>
                                <button onClick={handleGenerateNew} title="Generate New Content" className="oom-button generate-new" disabled={loading}>
                                    <AiGenerateIcon /> GENERATE NEW
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={monthlySearchVolumesModal} onClose={handleCloseModal}>
                <DialogTitle>Monthly Search Volume</DialogTitle>
                <DialogContent onClick={(e) => e.stopPropagation()}>
                    {selectedChartData ? (
                        <MonthlySearchChart monthlySearchVolumes={selectedChartData} changeDisplay={true} />
                    ) : (
                        <p>Loading chart...</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={topicImagePromptModal} onClose={handleCloseModal}>
                <DialogTitle>Generate Image</DialogTitle>
                <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    value={topicImagePrompt ? topicImagePrompt : selectedTopic?.topic}
                    onChange={(e) => setTopicImagePrompt(e.target.value)}
                    className="edit-prompt"
                    disabled={generatingTopicImage}
                    style={{ margin: '20px' }}
                />
                <DialogActions className="edit-action">
                    <div className="ai-suggest button">
                        <Button onClick={handleGenerateTopicImage} className="icon-svg gemini-arrow">
                            <AiGenerateIcon />
                            {generatingTopicImage ? (
                                <span className="text loading">Loading</span>
                            ) : (
                                <span className="text">Generate</span>
                            )}
                        </Button>
                    </div>
                    <div className="ai-suggest button">
                        <Button onClick={handleCloseModal} className="icon-svg gemini-arrow">
                            <span className="text">Cancel</span>
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}