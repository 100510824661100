import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PageComponent from './components/PageComponent';
import LoginPage from './views/Auth/LoginPage.jsx';
import RegisterPage from './views/Auth/RegisterPage';
import { jwtDecode } from 'jwt-decode';
import { refreshAccessToken } from "./utils/auth.js";

function App() {
   const [accessToken] = useState(localStorage.getItem('oom-access-token'));
   const [refreshToken] = useState(localStorage.getItem('oom-refresh-token'));

   return (
      <AuthProvider accessToken={accessToken} refreshToken={refreshToken}>
         <Router>
            <Routes>
               <Route path="/register" element={<RegisterPage />} />
               <Route path="/login" element={<LoginPage />} />
               <Route path="/:page/:id" element={<PageWrapper />} />
               <Route path="/:page" element={<PageWrapper />} />
               <Route path="/:page/:subpage/:id" element={<PageWrapper />} />
            </Routes>
         </Router>
      </AuthProvider>
   );
}

const PageWrapper = () => {
   const [refreshToken] = useState(localStorage.getItem('oom-refresh-token'));
   const { page, subpage, id} = useParams();
   const navigate = useNavigate();

   useEffect(() => {
      const token = localStorage.getItem('oom-access-token');

      if (!token && page !== "register") {
         navigate("/login");
         return;  
      }

      if (token) {
         try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp && decodedToken.exp < currentTime) {
               console.log('Token expired');

               if (refreshToken) {
                  refreshAccessToken(refreshToken)
                     .then(newAccessToken => {
                        localStorage.setItem('oom-access-token', newAccessToken);
                        console.log('Access token refreshed');
                     })
                     .catch(error => {
                        console.error('Failed to refresh access token:', error);
                        localStorage.removeItem('oom-access-token');
                        localStorage.removeItem('oom-refresh-token');
                        navigate("/login");
                     });
               } else {
                  console.log('No refresh token available');
                  localStorage.removeItem('oom-access-token');
                  localStorage.removeItem('oom-refresh-token');
                  navigate("/login");
               }
            } 
         } catch (error) {
            console.error("Error decoding token:", error);
         }
      }
      if (!token && page !== "register") {
         navigate("/login");
      }
   }, [page, navigate, refreshToken]);

   if (page === "login") {
      return <LoginPage />;
   }

   if (page === "register") {
      return <RegisterPage />;
   }
   return <PageComponent page={page} subpage={subpage} id={id} />;
};


export default App;
