/**
 * Dashboard
 *
 * @since 1.0.0
 *
 * @package OOmAISEOTools
 * @author  OOm Developer (oom_ss)
 */


import React from 'react';
import SiteHealthScore from '../components/widgets/SiteHealthScore';
import StatusOverview from '../components/widgets/StatusOverview';
import ProgressBar from '../components/widgets/ProgressBar';
import MultiProgressBar from '../components/widgets/MultiColorProgressBar';
import "../assets/css/Dashboard.css";

export default function Dashboard({profile}) {
    const data = [
        { title: "Errors", number: 0, color: "#800000" },
        { title: "Warnings", number: 7, color: "#ffae42" },
        { title: "Notices", number: 9, color: "#2C81FF" },
    ];

    return (
        <React.Fragment>
            <div className="oom-page-header">
                <div className="oom-page-attributes breadcrumb">
                    <p className="oom-page-attributes_breadcrumb">Dashboard</p>
                    <p className="oom-page-attributes_title">
                        Dashboard
                    </p>
                </div>
            </div>

            <div className="oom-page-content">
                <div className="oom-page-dashboard">
                    <div className="oom-container">
                        <div className="oom-column-30">
                            <div className="site-health-score">
                                <div className="site-health-score-header">
                                    <p>Site Health score</p>
                                </div>
                                <div className="site-health-score-body">
                                    <SiteHealthScore/>
                                </div>
                            </div>
                        </div>
                        <div className="oom-column-70">
                            <div className="status-overview-container">
                                <StatusOverview data={data} />
                            </div>
                            <div className="bar-chart-container">
                                <ProgressBar label="URLs Found" value={5060} max={6000} change={-3733} />
                                <ProgressBar label="On Page SEO Score" value={89} max={100} unit="" changeLabel="GREAT" />
                            </div>
                        </div>
                    </div>
                    <div className="oom-container">
                        <div className="oom-column-30">
                            <div className="site-health-score">
                                <div className="site-health-score-header">
                                    <p>Page crawled summary</p>
                                </div>
                                <div className="site-health-score-body">
                                    <MultiProgressBar/>
                                </div>
                            </div>
                        </div>
                        <div className="oom-column-70">
                            <div className="issue-report-container">
                            <div className="issue-report">
                                    <p>Issue Report</p>
                            </div>
                            <div className="issue-report">
                                    <p>Issue Report</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};